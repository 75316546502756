import {
  WalletAddress,
  WalletButtons,
  WalletTotalBalance,
  YourWalletContainer,
} from "./styled";
import { useWallet } from "@solana/wallet-adapter-react";
import { shortenAddress } from "../../../utils/addressUser";
import BlockCard from "../BlockCard";
import {
  BlockText,
  BlockTitle,
  BlockTitleFlex,
  ButtonCommon,
} from "../../../Layout/styled";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useWalletMultiButton } from "@solana/wallet-adapter-base-ui";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useSelector } from "react-redux";
import { convertFixed } from "../../../utils/convertNumber";
import { useDispatch } from "../../../redux/store";
import { UserProfile } from "../../../redux/slices/user";

const YourWallet = ({ handleCancelWallet }: any) => {
  const walletSol = useWallet();
  const { setVisible: setModalVisible } = useWalletModal();
  const { onDisconnect } = useWalletMultiButton({
    onSelectWallet() {
      setModalVisible(false);
    },
  });
  const dispatch = useDispatch()
  const { userProfile } = useSelector((state: any) => state.user);

  return (
    <YourWalletContainer>
      <WalletAddress>
        <p>Wallet Address</p>
        <div>
          <p>{shortenAddress(walletSol.publicKey?.toString())}</p>
          <CopyToClipboard
            text={`${walletSol.publicKey?.toString()}`}
            onCopy={() => toast.success("Coppied !")}
          >
            <span>
              Copy
              <img
                width={12}
                height={13}
                src="/img/common/copy.png"
                alt="icon"
                loading="lazy"
              />
            </span>
          </CopyToClipboard>
        </div>
      </WalletAddress>
      <WalletTotalBalance>
        <BlockCard
          icon={"/img/common/logo_small.png"}
          imgWidth={22}
          imgHeight={18}
          component={
            <BlockTitleFlex>
              <div className="column">
                <BlockTitle>Total Balance</BlockTitle>
                <BlockText>
                  {userProfile?.wic_coin ? convertFixed(userProfile?.wic_coin) : 0}
                  <span>WIC</span>
                </BlockText>
              </div>
            </BlockTitleFlex>
          }
        />
      </WalletTotalBalance>
      <WalletButtons>
        <ButtonCommon
          onClick={() => {
            handleCancelWallet();
          }}
          className="opacity"
        >
          CANCEL
        </ButtonCommon>
        <ButtonCommon
          onClick={() => {
            if (onDisconnect) {
              onDisconnect();
              handleCancelWallet();
              dispatch(UserProfile({}));
            }
          }}
          className="error"
        >
          DISCONNECT
        </ButtonCommon>
      </WalletButtons>
    </YourWalletContainer>
  );
};

export default YourWallet;
