import { useEffect, useRef, useState } from "react";
import {
  ChartData,
  ChartFilterBlock,
  ChartFilterButton,
  ChartHeader,
  ChartToken,
} from "./styled";
import ChartLine from "./Line";
import SelectCommon from "../Select";
import axios from "axios";
import { createChart, CrosshairMode } from "lightweight-charts";
import moment from "moment";
import { convertFixed } from "../../../utils/convertNumber";

const ChartCommon = ({ height, hideHeader }: any) => {
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>("Hour");
  const [selectedAggregate, setSelectedAggregate] = useState<number>(1);
  const onChangeSelect = (e: any) => {
    setSelectedTimeframe(e);
    setSelectedAggregate(1);
  };
  const chartContainerRef = useRef<any>();
  const chartRef = useRef<any>();
  const areaSeriesRef = useRef<any>();
  const tooltipRef = useRef<any>();
  const lastPriceRef = useRef<number | null>(null);
  const [percentageChange, setPercentageChange] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [chartData, setChartData] = useState<any>([]);

  const fetchHistoricalData = async () => {
    try {
      const response = await axios.get(
        `https://api.geckoterminal.com/api/v2/networks/solana/pools/2KwGKw7qZSxhbFLhUEfK877Ft2g6B1N9pC2KahPxQoFH/ohlcv/${selectedTimeframe}`,
        {
          params: {
            aggregate: selectedAggregate,
            limit: 500,
            currency: "usd",
          },
        }
      );

      const prices = response.data.data.attributes.ohlcv_list;

      // Only extract the closing price for the line chart
      const formattedData = prices.map((point: any) => ({
        time: point[0] / 1000, // Timestamp
        value: point[4], // Close price
      }));

      // Ensure data is sorted in ascending order by time
      formattedData.sort((a: any, b: any) => a.time - b.time);

      if (formattedData.length > 0) {
        const currentPrice = formattedData[formattedData.length - 1].value;

        if (lastPriceRef.current !== null) {
          const previousPrice = lastPriceRef.current;
          const change = ((currentPrice - previousPrice) / previousPrice) * 100;
          setPercentageChange(change);
        }

        lastPriceRef.current = currentPrice;
      }

      setLastUpdated(moment().format("HH:mm A [on] DD/MM/YYYY"));

      setChartData(formattedData);
    } catch (error) {
      console.error("Error fetching OHLCV data for ONI/WBNB:", error);
    }
  };

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      fetchHistoricalData();
    }, 10000);

    fetchHistoricalData();

    return () => clearInterval(fetchInterval);
  }, [selectedTimeframe, selectedAggregate]);

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      layout: {
        textColor: "#9F9F9F",
        background: { color: "transparent" },
      },
      grid: {
        vertLines: {
          color: "transparent",
        },
        horzLines: {
          color: "transparent",
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      timeScale: {
        borderColor: "#4E4E4E",
        rightOffset: 2,
        fixLeftEdge: true,
        fixRightEdge: true,
        tickMarkFormatter: (time: number) => {
          return moment(time * 1000).format("HH:mm");
        },
      },
      height: height || 450,
      width: chartContainerRef.current.clientWidth,
    });

    chartRef.current = chart;

    const areaSeries = chart.addAreaSeries({
      topColor: "rgba(21, 211, 171, 0.25)",
      bottomColor: "rgba(12, 128, 102, 0.1)",
      lineColor: "#15D3AB",
      lineWidth: 2,
      priceScaleId: "right",
      priceLineVisible: true,
      priceFormat: {
        type: "price",
        precision: 12,
        minMove: 0.00000000001,
      },
    });
    areaSeriesRef.current = areaSeries;

    const handleResize = () => {
      if (chartContainerRef.current) {
        const { width, height } =
          chartContainerRef.current.getBoundingClientRect();
        chart.applyOptions({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(chartContainerRef.current);

    chartContainerRef.current.addEventListener(
      "mousemove",
      (event: MouseEvent) => {
        if (!tooltipRef.current) return;

        const boundingRect = chartContainerRef.current.getBoundingClientRect();
        const x = event.clientX - boundingRect.left;
        const y = event.clientY - boundingRect.top;

        const time: any = chart.timeScale().coordinateToTime(x);
        const price = areaSeries.coordinateToPrice(y);

        const closestDataPoint = chartData.reduce((prev: any, curr: any) => {
          return Math.abs(curr.time - time) < Math.abs(prev.time - time)
            ? curr
            : prev;
        }, chartData[0] || { time: 0, value: 0 });

        if (time && price !== null) {
          tooltipRef.current.style.display = "block";
          tooltipRef.current.innerHTML = `SOL/WIC: ${convertFixed(
            closestDataPoint.value
          )}`;
          tooltipRef.current.style.left = `${x}px`;
          tooltipRef.current.style.top = `${y}px`;
        } else {
          tooltipRef.current.style.display = "none";
        }
      }
    );

    chartContainerRef.current.addEventListener("mouseleave", () => {
      if (tooltipRef.current) {
        tooltipRef.current.style.display = "none";
      }
    });

    return () => {
      resizeObserver.disconnect();
      chart.remove();
    };
  }, [height, selectedTimeframe, chartData]);

  useEffect(() => {
    if (areaSeriesRef.current && chartData.length > 0) {
      areaSeriesRef.current.setData(chartData);
      chartRef.current.timeScale().fitContent();
    }
  }, [chartData]);

  return (
    <div>
      {hideHeader ? (
        <></>
      ) : (
        <ChartHeader>
          <ChartData>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ChartToken>
                  <img
                    width={56}
                    height={54}
                    src="/img/Common/wicdog_icon.png"
                    alt="WIC"
                    loading="lazy"
                  />
                </ChartToken>
              </div>
              <div>
                <p>SOL/WIC</p>
                <p
                  style={{
                    marginBottom: "0",
                  }}
                >
                  {chartData.length > 0
                    ? convertFixed(chartData[chartData.length - 1].value)
                    : "Loading..."}
                </p>
              </div>
            </div>
            {/* <span className="color">
            {lastUpdated
              ? `Last updated: ${lastUpdated} ( Refresh every minute )`
              : "Fetching data..."}
          </span>
          {percentageChange === 0 ? (
            <span>There was nothing changed</span>
          ) : (
            <span
              style={{ color: percentageChange > 0 ? "#59FF9E" : "#FF5252" }}
            >
              {percentageChange > 0
                ? `Increased by ${percentageChange.toFixed(2)}%`
                : `Decreased by ${Math.abs(percentageChange).toFixed(2)}%`}{" "}
              since last minute.
            </span>
          )} */}
          </ChartData>
          <ChartFilterBlock>
            <div>
              <SelectCommon
                defaultValue="Hour"
                data={[
                  {
                    title: "Minute",
                    img: "",
                  },
                  {
                    title: "Hour",
                    img: "",
                  },
                  {
                    title: "Day",
                    img: "",
                  },
                ]}
                onChange={onChangeSelect}
              />
            </div>
            <div>
              <div>
                {selectedTimeframe === "Minute" &&
                  [1, 5, 15].map((agg) => (
                    <ChartFilterButton>
                      <button
                        className={agg === selectedAggregate ? "active" : ""}
                        key={agg}
                        onClick={() => setSelectedAggregate(agg)}
                      >
                        {agg} minutes
                      </button>
                    </ChartFilterButton>
                  ))}
                {selectedTimeframe === "Hour" &&
                  [1, 4, 12].map((agg) => (
                    <ChartFilterButton>
                      <button
                        className={agg === selectedAggregate ? "active" : ""}
                        key={agg}
                        onClick={() => setSelectedAggregate(agg)}
                      >
                        {agg} hours
                      </button>
                    </ChartFilterButton>
                  ))}
                {selectedTimeframe === "Day" && (
                  <ChartFilterButton>
                    <button onClick={() => setSelectedAggregate(1)}>
                      1 day
                    </button>
                  </ChartFilterButton>
                )}
              </div>
            </div>
          </ChartFilterBlock>
        </ChartHeader>
      )}

      <ChartLine
        tooltipRef={tooltipRef}
        chartContainerRef={chartContainerRef}
      />
    </div>
  );
};

export default ChartCommon;
