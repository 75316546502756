import { instance } from "../instance";

export const WicAPI = {
  authLogin(param: any) {
    return instance.post(`auth/connect-wallet`, param);
  },
  checkUserExist(param: any) {
    return instance.get(`users/${param}/check`);
  },
  userBalances() {
    return instance.get(`users/balances`);
  },
  settings() {
    return instance.get(
      `settings/?pagination[page]=1&pagination[perPage]=10000`
    );
  },
  userInfo() {
    return instance.get(`users/me`);
  },
  updateUser(param: any, signature: any, message: any) {
    return instance.patch(`users`, param, {
      headers: {
        ...instance.defaults.headers.common,
        signature: signature,
        message: message,
      },
    });
  },
  tokenPrice() {
    return instance.get(`tokens/price`);
  },
  networkTree() {
    return instance.get(`networks/tree`);
  },
  network() {
    return instance.get(`networks`);
  },
  affiliate() {
    return instance.get(`affiliate`);
  },
  buyPackage(param: any, signature: any, message: any) {
    return instance.post(`packages/buy`, param, {
      headers: {
        ...instance.defaults.headers.common,
        signature: signature,
        message: message,
      },
    });
  },
  buyPackageOnChain(param: any, signature: any, message: any) {
    return instance.post(`packages/buyOnchain`, param, {
      headers: {
        ...instance.defaults.headers.common,
        signature: signature,
        message: message,
      },
    });
  },
  transaction() {
    return instance.get(
      `transactions?pagination[page]=1&pagination[perPage]=10000`
    );
  },
  transfer(param: any, signature: any, message: any) {
    return instance.post(`wallets/transfer`, param, {
      headers: {
        ...instance.defaults.headers.common,
        signature: signature,
        message: message,
      },
    });
  },
  withdraw(param: any, signature: any, message: any) {
    return instance.post(`wallets/withdraw`, param, {
      headers: {
        ...instance.defaults.headers.common,
        signature: signature,
        message: message,
      },
    });
  },
  stakingHistory() {
    return instance.get(`stakings`);
  },
  stakingSubmit(param: any, signature: any, message: any) {
    return instance.post(`stakings`, param, {
      headers: {
        ...instance.defaults.headers.common,
        signature: signature,
        message: message,
      },
    });
  },
  buyOTC(param: any) {
    return instance.post(
      `wallets/buyTokenOTC`,
      param
      //   , {
      //   headers: {
      //     ...instance.defaults.headers.common,
      //     signature: signature,
      //     message: message,
      //   },
      // }
    );
  },
  estimatePrice(param: any) {
    return instance.post(
      `wallets/estimateTokenOTC`,
      param
    );
  },
};
