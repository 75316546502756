import styled from "styled-components";

export const ModalSelectedDataStaking = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 15px;
  & > li {
    padding: 20px 16px;
    width: calc(50% - 35px);
    border-radius: 6px;
    background: #282828;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: #fff;
      & > span {
        background: linear-gradient(220deg, #8dffcf 11.31%, #19fb9b 83.28%);
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
      }
      & > div {
        h2,
        p {
          color: #424242;
        }
      }
    }
    & > span {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-top: -10px;
      padding: 3px 10px;
      width: max-content;
      border-radius: 4px;
      font-size: 11px;
      font-weight: 500;
      background: linear-gradient(180deg, #7a7a7a 0%, #e0e0e0 100%);
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
      transition: all 0.15s linear;
    }
    & > div {
      margin-top: -5px;
      h2,
      p {
        transition: all 0.15s linear;
      }
      h2 {
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 3px;
      }
      p {
        display: flex;
        flex-direction: column;
        color: #fff;
        font-size: 14px;
        span {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    & > li {
      width: 100%;
    }
  }
`;
