import { useEffect, useState } from "react";
import { ButtonCommon } from "../../../Layout/styled";
import {
  ModalBalance,
  ModalBuyContainer,
  ModalTabs,
  ModalTabsWrapper,
} from "../../Buy/Modal/styled";
import { ModalSelectedDataStaking } from "./styled";
import { convertFixed } from "../../../utils/convertNumber";
import { useSelector } from "react-redux";
import { WicAPI } from "../../../services/api";
import { ModalInputAmount } from "../../Home/styled";
import InputCommon from "../../../components/Common/Input";
import { IsLoadingRedux } from "../../../redux/slices/user";
import { useDispatch } from "../../../redux/store";
import toast from "react-hot-toast";
import { useWallet } from "@solana/wallet-adapter-react";
import LoadingText from "../../../components/Common/Loading/Text";

const ModalStaking = ({ handleCancel }: any) => {
  const walletSol = useWallet();
  const { userProfile, tokenPrice, settings } = useSelector((state: any) => state.user);
  const [isPending, setIsPending] = useState(false);
  const [itemSelect, setItemSelect] = useState({
    type: 7,
    amount: 0,
  });

  const tabData = [
    // {
    //   id: 1,
    //   icon: "/img/common/wiccoin_icon.png",
    //   name: "WIC Wallet",
    // },
    {
      id: 2,
      icon: "/img/common/wiccoin_icon.png",
      name: "WICCOIN",
    },
  ];

  const selectedData = [
    {
      id: 1,
      title: "Stake Pool 180 days",
      price: 1631114290.92,
      tag: "6%/180 days",
      day: 180,
    },
    {
      id: 2,
      title: "Stake Pool 90 days",
      price: 746234760.52,
      tag: "3%/90 days",
      day: 90,
    },
    {
      id: 3,
      title: "Stake Pool 30 days",
      price: 202134360,
      tag: "1%/30 days",
      day: 30,
    },
    {
      id: 4,
      title: "Stake Pool 7 days",
      price: 53232132.1,
      tag: "0.25%/7 days",
      day: 7,
    },
  ];

  const minAmountStaking = walletSol.publicKey ? Number(
    settings.filter((setting: any) => setting.name === "Min.Coin.Staking")[0]
      .value
  ) : 0;

  const dispatch = useDispatch();

  // Sign and validate when staking
  const [signatureData, setSignatureData] = useState<any>({});
  const message = new TextEncoder().encode("Confirm staking");
  const handleSignWhenStaking = async () => {
    setIsPending(true);

    if (!walletSol.publicKey) {
      toast.error("Please connect wallet", { id: "wallet" });
      setIsPending(false);
      return;
    }

    if (minAmountStaking > Number(itemSelect.amount)) {
      toast.error(`Minimum amount is ${minAmountStaking}`, { id: "minimum" });
      setIsPending(false);
      return;
    }

    if (Number(itemSelect.amount) === 0 || isNaN(Number(itemSelect.amount))) {
      toast.error("Please enter amount", { id: "amount" });
      setIsPending(false);
      return;
    }
    if (Number(itemSelect.amount) > Number(userProfile?.wic_coin)) {
      toast.error("Insufficient Balance", { id: "balance" });
      setIsPending(false);
      return;
    }

    try {
      const signature =
        walletSol.signMessage && (await walletSol.signMessage(message));
      setSignatureData(signature);
    } catch (error) {
      console.error("Error", error);
      toast.error("Rejected", { id: "rejected" });
      setIsPending(false);
      setSignatureData({});
    }
  };

  const handleTableStaking = async () => {
    setIsPending(true);
    if (!walletSol.publicKey) {
      toast.error("Please connect wallet", { id: "wallet" });
      return;
    }
    try {
      const res = await WicAPI.stakingSubmit(
        {
          amount: Number(itemSelect.amount),
          type: itemSelect.type,
        },
        Buffer.from(signatureData).toString("base64"),
        "Confirm staking"
      );
      if (res.status === 202) {
        setIsPending(false);
        setSignatureData({});
        handleCancel();
        dispatch(IsLoadingRedux(true));
        toast.success("Staking successfully", { id: "success" });
      }
    } catch (error) {
      console.log(error);
      setSignatureData({});
      setIsPending(false);
    }
  };

  useEffect(() => {
    if (signatureData && Object.keys(signatureData).length > 0) {
      handleTableStaking();
    }
  }, [signatureData]);

  return (
    <ModalBuyContainer>
      <ModalTabsWrapper className="staking">
        <h2>Payment Source</h2>
        <ModalTabs>
          {tabData.map((item, index) => {
            return (
              <li
                style={{
                  width: "100%",
                }}
                key={index}
              >
                <figure>
                  <img
                    width={28}
                    height={27}
                    src={item.icon}
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                <p>{item.name}</p>
              </li>
            );
          })}
        </ModalTabs>
      </ModalTabsWrapper>
      <ModalBalance>
        <figure>
          <img src="/img/common/balance_icon.svg" alt="icon" loading="lazy" />
        </figure>
        Balance:{" "}
        {userProfile?.wic_coin ? convertFixed(userProfile?.wic_coin) : 0} WIC =
        $
        {userProfile?.wic_coin
          ? convertFixed(Number(userProfile?.wic_coin) * Number(tokenPrice))
          : 0}
      </ModalBalance>
      <ModalSelectedDataStaking>
        {selectedData.map((item, index) => {
          return (
            <li
              className={itemSelect.type === item.day ? "active" : ""}
              key={index}
              onClick={() => {
                setItemSelect({
                  ...itemSelect,
                  type: item.day,
                });
              }}
            >
              <span>{item.tag}</span>
              <div>
                <h2>{item.title}</h2>
                <p
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  <span>
                    {" "}
                    Total WIC Staked: {convertFixed(item.price)}
                    <img
                      width={20}
                      height={20}
                      src="/img/common/wiccoin_icon.png"
                      alt="icon"
                      loading="lazy"
                    />
                  </span>
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#6e6d6b",
                  }}
                >
                  $
                  {!tokenPrice
                    ? 0
                    : convertFixed(Number(item.price) * Number(tokenPrice))}
                </p>
              </div>
            </li>
          );
        })}
      </ModalSelectedDataStaking>
      <ModalInputAmount>
        <p>Amount</p>
        <InputCommon
          type="number"
          placeHolder="Enter Amount"
          value={!isNaN(Number(itemSelect.amount)) && itemSelect.amount}
          suffix={
            <img
              width={28}
              height={27}
              src="/img/common/wiccoin_icon.png"
              alt="icon"
              loading="lazy"
            />
          }
          onChange={(e: any) => {
            setItemSelect({ ...itemSelect, amount: Number(e.target.value) });
          }}
        />
      </ModalInputAmount>
      <ButtonCommon
        disabled={isPending}
        onClick={() => {
          handleSignWhenStaking();
        }}
        style={{
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        className="opacity"
      >
        {isPending ? (
          <LoadingText />
        ) : (
          <p>{!walletSol.publicKey ? "Please connect wallet" : "Confirm"}</p>
        )}
      </ButtonCommon>
    </ModalBuyContainer>
  );
};

export default ModalStaking;
