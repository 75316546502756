import CopyToClipboard from "react-copy-to-clipboard";
import TableCommon from "../../../components/Common/Table";
import { AddressTable } from "../../../Layout/styled";
import { shortenAddress } from "../../../utils/addressUser";
import { TransactionTable } from "../../Home/Transaction/styled";
import toast from "react-hot-toast";
import { InvitersTableContainer } from "./styled";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import { convertFixed } from "../../../utils/convertNumber";

const InvitersTable = ({ data }: any) => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const headingData = [
    {
      name: "Address",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <CopyToClipboard
            text={data.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <AddressTable>
              {shortenAddress(data.address)}
              <figure>
                <img
                  width={12}
                  height={13}
                  src="/img/common/copy.png"
                  alt="icon"
                  loading="lazy"
                />
              </figure>
            </AddressTable>
          </CopyToClipboard>
        );
      },
    },
    {
      name: `Ranking`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {isMobile ? "Ranking: " : ""}
            {data.ranking}
          </p>
        );
      },
    },
    {
      name: `Level`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {isMobile ? "Level: " : ""} {data.level}
          </p>
        );
      },
    },
    {
      name: `Total Invest`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {isMobile ? "Total Invest: " : ""} ${convertFixed(data.total_package_usd)}
          </p>
        );
      },
    },
    {
      name: `Sponser`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {isMobile ? "Sponser: " : ""} {shortenAddress(data.sponser)}
          </p>
        );
      },
    },
  ];

  return (
    <InvitersTableContainer>
      <TransactionTable>
        <TableCommon data={data} heading={headingData} />
      </TransactionTable>
    </InvitersTableContainer>
  );
};

export default InvitersTable;
