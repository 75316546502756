import styled from "styled-components";

export const SwitchTabWrapper = styled.div`
  margin-bottom: 10px;
  & > ul {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 9px;
    padding: 6px;
    background: #191919;
    & > li {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 50%;
      border-radius: 6px;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.28px;
      color: #555;
      background: rgba(33, 33, 33, 0.01);
      transition: all .15s linear;
      &:hover,
      &.active {
        color: #fc0;
        background: #2f2f2f;
      }
    }
  }
`;
