import { PendingContainer } from "./styled";
import Typewriter from "typewriter-effect";

const WaitingConfirm = () => {
  return (
    <PendingContainer>
      <Typewriter
        options={{
          strings: [
            "Transaction is still progressing...",
            "Please do not close window",
          ],
          autoStart: true,
          loop: true,
        }}
      />
    </PendingContainer>
  );
};

export default WaitingConfirm;
