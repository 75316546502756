import styled from "styled-components";
import { ButtonCommon } from "../../Layout/styled";

export const HeaderContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(12.5px);
  .custom {
    background-color: transparent;
  }
`;
export const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 18px 20px;
  max-width: 1160px;
  margin: 0 auto;
  @media screen and (max-width: 1023px) {
    padding-left: 15px;
    padding-right: 15px;
    gap: 10px;
    a {
      display: block;
      height: 30px;
      img {
        width: 90px;
        height: auto;
      }
    }
    ${ButtonCommon} {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
`;
export const NavigationList = styled.ul`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 9px;
  padding: 6px;
  background: #191919;
  & > li {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 12px;
      border-radius: 6px;
      font-size: 15px;
      color: #555;
      letter-spacing: 0.28px;
      transition: all 0.15s linear;
      &.active,
      &:hover {
        border-radius: 6px;
        background: #2f2f2f;
        text-shadow: 0px 0px 18.1px #ffd773;
        color: #fc0;
        font-weight: 700;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    & > li {
      a {
        font-size: 12px;
        padding: 6px;
      }
    }
  }
`;
export const NavigationMobile = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  background: #303030;
  backdrop-filter: blur(46px);
  padding: 5px 10px 10px;
  & > div {
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.active {
        figure {
          opacity: 1;
        }
        p {
          color: #fff;
        }
      }
      figure {
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
        mix-blend-mode: luminosity;
        opacity: 0.3;
      }
    }
    p {
      color: #6e6e6e;
      text-align: center;
      font-size: 13px;
    }
  }
`;
export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & > figure {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 10px;
    background: #535353;
    cursor: pointer;
    transition: all 0.15s linear;
    &:hover {
      opacity: 0.7;
    }
  }
  & > button {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    & > span {
      &.css-zwyh0-CurrentUserBadge-CurrentUserBadge {
        font-size: 15px;
        line-height: normal;
      }
    }
  }
`;
