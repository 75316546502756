import { useSelector } from "react-redux";
import { ButtonCommon } from "../../../Layout/styled";
import { CardAction, CardButtons } from "../styled";
import { useState } from "react";

const CardActions = ({ setIsModalSwapOpen, setIsModalTransferOpen }: any) => {
  const { userProfile } = useSelector((state: any) => state.user);
  const [levelBlock, setLevelBlock] = useState("")
  const handleSwitchLevel = () => {
    switch (userProfile?.leadership_level_info) {
      case 1:
        return setLevelBlock("moon");
      case 2:
        return setLevelBlock("sun");
      case 3:
        return setLevelBlock("rainbow");
      case 4:
        return setLevelBlock("space");
      default:
        return setLevelBlock("normal");
    }
  };

  return (
    <CardAction>
      <figure>
        <img
          width={82}
          height={69}
          src="/img/common/logo_big.png"
          alt="icon"
          loading="lazy"
        />
      </figure>
      <CardButtons>
        <ButtonCommon
          onClick={() => {
            setIsModalTransferOpen(true);
          }}
          className="opacity"
        >
          Transfer
        </ButtonCommon>
        <ButtonCommon
          onClick={() => {
            setIsModalSwapOpen(true);
          }}
          className="opacity"
        >
          Swap
        </ButtonCommon>
      </CardButtons>
    </CardAction>
  );
};

export default CardActions;
