import { useEffect, useState } from "react";
import BlockCard from "../../../components/Common/BlockCard";
import ChartCommon from "../../../components/Common/Chart";
import {
  BlockText,
  BlockTitle,
  BlockTitleFlex,
  FrameBlock,
  TitleDecor,
} from "../../../Layout/styled";
import {
  ListTransaction,
  NetworkBody,
  NetworkContainer,
  NetworkList,
  TransactionLeft,
  TransactionRight,
  TransactionTag,
} from "./styled";
import { shortenAddress } from "../../../utils/addressUser";
import { convertFixed } from "../../../utils/convertNumber";

const generateSolanaHash = () => {
  return `${Math.random().toString(36).substring(2, 15)}${Math.random()
    .toString(36)
    .substring(2, 15)}`;
};

const generateSolanaAddress = () => {
  return `${Math.random().toString(36).substring(2, 15)}${Math.random()
    .toString(36)
    .substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`;
};

const DashboardNetwork = () => {
  const [transactions, setTransactions] = useState<any>([]);

  const generateRandomTransaction = (maxSecondsAgo: number) => {
    const randomId = Math.floor(Math.random() * 100000);
    const randomToken = `${(Math.random() * 1e12).toFixed(2)}`;
    const randomSecondsAgo = Math.floor(Math.random() * 20) + 1;
    const newTime = Math.max(Math.min(randomSecondsAgo, maxSecondsAgo - 1), 1);
    const randomTime = `${newTime} seconds ago`;
    const randomHash = generateSolanaHash();
    const randomBlock = `#${Math.floor(Math.random() * 1e9)}`;
    const randomAddressFrom = generateSolanaAddress();
    const randomAddressTo = generateSolanaAddress();
    const randomType = Math.random() > 0.5 ? "Deposit" : "Receive";

    return {
      id: randomId,
      token: randomToken,
      time: randomTime,
      hash: randomHash,
      block: randomBlock,
      from: randomAddressFrom,
      to: randomAddressTo,
      type: randomType,
    };
  };

  const generateRandomTransactions = () => {
    const newTransactions: any = [];
    let maxSecondsAgo = 60;

    for (let i = 0; i < 8; i++) {
      const newTransaction = generateRandomTransaction(maxSecondsAgo);
      newTransactions.push(newTransaction);
      maxSecondsAgo = parseInt(newTransaction.time);
    }

    newTransactions.sort(
      (a: any, b: any) => parseInt(a.time) - parseInt(b.time)
    );

    setTransactions(newTransactions);
  };

  useEffect(() => {
    generateRandomTransactions();

    const interval = setInterval(() => {
      generateRandomTransactions();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <NetworkContainer>
      <TitleDecor>WICCOIN NETWORK</TitleDecor>
      <NetworkBody>
        <FrameBlock>
          <NetworkList>
            <li>
              <BlockCard
                icon={"/img/common/cube_icon.svg"}
                imgWidth={25}
                imgHeight={25}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Total Blocks</BlockTitle>
                      <BlockText>379,982</BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            <li>
              <BlockCard
                icon={"/img/common/block_time_icon.svg"}
                imgWidth={25}
                imgHeight={25}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Average Block Time</BlockTitle>
                      <BlockText>1.00s</BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            <li>
              <BlockCard
                icon={"/img/common/notebook_icon.svg"}
                imgWidth={25}
                imgHeight={25}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Total Transactions</BlockTitle>
                      <BlockText>12,739,656</BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            <li>
              <BlockCard
                icon={"/img/common/signal_icon.svg"}
                imgWidth={25}
                imgHeight={25}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Total Volume</BlockTitle>
                      <BlockText>
                        2,939,044 <span>USDT</span>
                      </BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            <li>
              <BlockCard
                icon={"/img/common/signal_24_icon.svg"}
                imgWidth={25}
                imgHeight={25}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Total Volume 24h</BlockTitle>
                      <BlockText>
                        3,684,927
                        <span>USDT</span>
                      </BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            <li>
              <BlockCard
                icon={"/img/common/credit_icon.svg"}
                imgWidth={25}
                imgHeight={25}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Wallet Address</BlockTitle>
                      <BlockText>13,108</BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
          </NetworkList>
          <ChartCommon hideHeader={true} />
        </FrameBlock>
        <FrameBlock>
          <h2>Last Transactions</h2>
          <ListTransaction>
            {transactions.map((item: any, index: any) => {
              return (
                <li key={index}>
                  <TransactionLeft>
                    <p>
                      {convertFixed(Number(item.token))}
                      <span
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <img
                          width={20}
                          height={20}
                          src="/img/common/wiccoin_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      </span>
                      <TransactionTag>{item.type}</TransactionTag>
                    </p>
                    <p>
                      <span
                        style={{
                          color: "#fbbf24",
                        }}
                      >
                        {shortenAddress(item.from)}
                      </span>
                      <img
                        src="/img/common/rectangle_arrow.svg"
                        alt="icon"
                        loading="lazy"
                      />
                      <span
                        style={{
                          color: "#34d399",
                        }}
                      >
                        {shortenAddress(item.to)}
                      </span>
                    </p>
                  </TransactionLeft>
                  <TransactionRight>
                    <span>{item.time}</span>
                    <p
                      style={{
                        color: "#fff",
                      }}
                    >
                      TX Hash:{" "}
                      <span
                        style={{
                          color: "#60a5fa",
                        }}
                      >
                        {shortenAddress(item.hash)}
                      </span>
                    </p>
                    <p>{item.block}</p>
                  </TransactionRight>
                </li>
              );
            })}
          </ListTransaction>
        </FrameBlock>
      </NetworkBody>
    </NetworkContainer>
  );
};

export default DashboardNetwork;
