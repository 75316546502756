import { Dispatch, createSlice } from "@reduxjs/toolkit";

interface DriverState {
  userProfile: any;
  tokenPrice: any;
  isLoading: any;
  wicPrice: any;
  transaction: any;
  settings: any;
}

const initialState: DriverState = {
  userProfile: null,
  tokenPrice: 0,
  isLoading: false,
  wicPrice: null,
  transaction: null,
  settings: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserProfile(state, action) {
      state.userProfile = action.payload;
    },
    getSettings(state,action) {
      state.settings = action.payload;
    },
    getTokenPrice(state, action) {
      state.tokenPrice = action.payload;
    },
    getWicPrice(state, action) {
      state.wicPrice = action.payload;
    },
    getTransaction(state, action) {
      state.transaction = action.payload;
    },
    isLoadingSuccess(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export function Settings(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getSettings(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function UserProfile(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getUserProfile(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function IsLoadingRedux(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.isLoadingSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getTokenPrice(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getTokenPrice(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getWicPrice(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getWicPrice(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getTransaction(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getTransaction(data));
    } catch (error) {
      console.log(error);
    }
  };
}

const userReducer = userSlice.reducer;

export default userReducer;
