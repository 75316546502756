import styled from "styled-components";

export const PendingContainer = styled.div`
  background: url("/img/common/bg_rock.jpg") no-repeat center / cover;
  border-radius: 10px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  .Typewriter__wrapper {
    font-size: 20px;
  }
`;
