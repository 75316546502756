import styled from "styled-components";
import { NodeProfile } from "../Tree/styled";

export const NodeMenu = styled.div`
  position: absolute;
  right: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  padding: 5px;
  svg {
    path {
      fill: #fff;
    }
  }
`;
export const NodeLevel = styled.div`
  position: absolute;
  right: 30px;
  bottom: 44px;
  & > p {
    position: absolute;
    left: 50%;
    transform: translateX(-60%);
    bottom: -25px;
    font-size: 15px;
    font-style: italic;
    font-weight: 600;
  }
`;
export const NodeWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  padding: 12px;
  background: url("/img/Common/graph_chart.jpg") no-repeat center / cover;
  border: 1px solid #484848;
  &.level_00 {
    background-image: url("/img/Common/graph_chart_0.jpg");
    ${NodeLevel} {
      & > p {
        color: #fff;
      }
    }
  }
  &.level_0 {
    background-image: url("/img/Common/graph_chart_00.jpg");
    ${NodeLevel} {
      & > p {
        color: #fff;
      }
    }
  }
  &.level_1 {
    background-image: url("/img/Common/graph_chart_01.jpg");
    ${NodeLevel} {
      & > p {
        color: #81c8ff;
      }
    }
  }
  &.level_2 {
    background-image: url("/img/Common/graph_chart_02.jpg");
    ${NodeProfile} {
      p {
        color: #004352;
      }
    }
    ${NodeLevel} {
      & > p {
        color: #fff;
      }
    }
  }
  &.level_3 {
    background-image: url("/img/Common/graph_chart_03.jpg");
    ${NodeLevel} {
      & > p {
        color: #d6a7ff;
      }
    }
  }
  &.level_4 {
    background-image: url("/img/Common/graph_chart_04.jpg");
    ${NodeLevel} {
      & > p {
        color: #ffc9c4;
      }
    }
  }
  &.level_5 {
    background-image: url("/img/Common/graph_chart_05.jpg");
    ${NodeProfile} {
      p {
        color: #000;
      }
    }
    ${NodeLevel} {
      & > p {
        color: #ffe89d;
      }
    }
  }
  &.level_6 {
    background-image: url("/img/Common/graph_chart_06.jpg");
    ${NodeLevel} {
      & > p {
        color: #ffd0c0;
      }
    }
  }
`;
