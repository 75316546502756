import styled from "styled-components";
import { InputContainer } from "../../../components/Common/Input/styled";
import { SelectContainer } from "../../../components/Common/Select/styled";

export const HistoryFilter = styled.div`
  display: flex;
  margin-bottom: 15px;
  gap: 4px;
  ${InputContainer} {
    width: 40%;
  }
  ${SelectContainer} {
    width: 15%;
  }
`;
export const TransactionTable = styled.div``;
