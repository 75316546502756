import styled from "styled-components";
import { BlockCardLayout, ButtonCommon } from "../../../Layout/styled";

export const YourWalletContainer = styled.div``;
export const WalletAddress = styled.div`
  position: relative;
  background-color: #2c2c2c;
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 12px;
  & > p {
    color: #757575;
    font-size: 14px;
    margin-bottom: 5px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 16px;
      color: #fff;
    }
    span {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      color: #f5c754;
      font-weight: 500;
      cursor: pointer;
    }
  }
`;
export const WalletTotalBalance = styled.div`
  margin-bottom: 15px;
  ${BlockCardLayout} {
    width: calc(100% - 25px);
  }
`;
export const WalletButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${ButtonCommon} {
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 70%;
    }
  }
`;
