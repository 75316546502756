import { useWallet } from "@solana/wallet-adapter-react";
import TableCommon from "../../../../components/Common/Table";
import { TransactionTable } from "../../../Home/Transaction/styled";
import { LeftTeam, LeftTeamHeader, TeamHeader, TeamInfo } from "../styled";
import { shortenAddress } from "../../../../utils/addressUser";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { AddressTable } from "../../../../Layout/styled";
import { InvitersTableContainer } from "../../Inviters/styled";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../../../components/Context";
import { convertFixed } from "../../../../utils/convertNumber";

const LTeam = ({ data }: any) => {
  const walletSol = useWallet();
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const headingData = [
    {
      name: "Address",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <CopyToClipboard
            text={data.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <AddressTable>
              {shortenAddress(data.address)}
              <figure>
                <img
                  width={12}
                  height={13}
                  src="/img/common/copy.png"
                  alt="icon"
                  loading="lazy"
                />
              </figure>
            </AddressTable>
          </CopyToClipboard>
        );
      },
    },
    {
      name: `Sponser`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {isMobile ? "Sponser: " : ""} {shortenAddress(data.sponser)}
          </p>
        );
      },
    },
    {
      name: `Total Invest`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {isMobile ? "Total Invest: " : ""} ${convertFixed(data.total_package_usd)}
          </p>
        );
      },
    },
  ];

  return (
    <LeftTeam>
      <LeftTeamHeader>
        <TeamHeader>
          <figure>
            <img
              width={22}
              height={18}
              src="/img/common/logo_small.png"
              alt="icon"
              loading="lazy"
            />
          </figure>
          <p>Left Team</p>
        </TeamHeader>
        <TeamInfo>
          <div>
            <p>Member</p>
            <p>{walletSol.publicKey ? data?.left_team_number : "0"}</p>
          </div>
          <div>
            <p>Total Revenue</p>
            <p>
              ${walletSol.publicKey
                ? convertFixed(data?.left_team_revenue?.usd)
                : "0"}
            </p>
          </div>
          <div>
            <p>Total Commission</p>
            <p>
              ${walletSol.publicKey
                ? convertFixed(data?.total_commission_branch_left)
                : "0"}
            </p>
          </div>
        </TeamInfo>
      </LeftTeamHeader>
      <InvitersTableContainer>
        <TransactionTable>
          <TableCommon data={data?.left} heading={headingData} />
        </TransactionTable>
      </InvitersTableContainer>
    </LeftTeam>
  );
};

export default LTeam;
