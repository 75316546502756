import { useEffect, useState } from "react";
import { BinaryContainer, BinaryNode } from "./styled";
import Tree from "react-d3-tree";
import NodeItem from "../Node";
import { useWallet } from "@solana/wallet-adapter-react";
import EmptyText from "../../../components/Empty";
import ModalWrapper from "../../../components/Common/Modal";
import TreeModal from "./Modal";
import { SelectContainer } from "../../../components/Common/Select/styled";
import { AutoComplete } from "antd";

const BinaryTree = ({ binaryData }: any) => {
  const walletSol = useWallet();
  const [treeData, setTreeData] = useState<any>(null);
  const [target, setTarget] = useState<any>();
  const [targetSearch, setTargetSearch] = useState<any>();
  const [targetClicked, setTargetClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [value, setValue] = useState("");
  const onChange = (data: string) => {
    setValue(data);
  };

  const convertBinaryDataToTree = (node: any, isLeftNode = true) => {
    if (!node || !node.node) return null;
    const newNode: any = {
      name: !node.node.fullname ? node.node.address : node.node.fullname,
      attributes: {
        fullname: !node.node.fullname ? node.node.address : node.node.fullname,
        address: node.node.address,
        email: node.node.email,
        accountID: node.node.user_id,
        created_at: node.node.created_at,
        leadership_level: node.node.leadership_level,
        left_revenue: node.node.left_branch_revenue,
        right_revenue: node.node.right_branch_revenue,
        package: node.node.package,
        total_package_usd: node.node.total_package_usd
      },
      leftNode: isLeftNode,
      children: [],
    };

    if (node.left && node.left.node) {
      const leftChild = convertBinaryDataToTree(node.left, true);
      if (leftChild) {
        newNode.children.push(leftChild);
      }
    }

    if (node.right && node.right.node) {
      const rightChild = convertBinaryDataToTree(node.right, false);
      if (rightChild) {
        newNode.children.push(rightChild);
      }
    }

    return newNode;
  };

  const collectTreeOptions = (node: any, collectedOptions: any[] = []) => {
    if (!node) return collectedOptions;
    if (node.name) {
      collectedOptions.push({
        value: node.name,
        text: node.name,
      });
    }
    node.children.forEach((child: any) =>
      collectTreeOptions(child, collectedOptions)
    );
    return collectedOptions;
  };

  useEffect(() => {
    if (binaryData) {
      const tree = convertBinaryDataToTree(binaryData);
      setTreeData([tree]);
      const allOptions = collectTreeOptions(tree);
      setOptions(allOptions);
    }
  }, [binaryData]);

  const renderRectSvgNode = ({
    nodeDatum,
    toggleNode,
    hierarchyPointNode,
  }: any) => {
    const isLeftNodePosition = nodeDatum.leftNode === true;
    const isLeftNode = nodeDatum?.children?.length > 0 ? true : false;
    const x = isLeftNodePosition ? -35 : 20;
    return (
      <g>
        <circle strokeWidth="1" fill={isLeftNode ? "#2c2c2c" : "red"} r="10" />
        <NodeItem
          target={target}
          hierarchyPointNode={hierarchyPointNode}
          nodeDatum={nodeDatum}
          toggleNode={toggleNode}
          setTarget={setTarget}
          isLeftNodePosition={isLeftNodePosition}
          setTargetClicked={setTargetClicked}
          targetClicked={targetClicked}
          x={x}
          setIsModalOpen={setIsModalOpen}
          setTargetSearch={setTargetSearch}
          value={value}
        />
      </g>
    );
  };

  return (
    <>
      <ModalWrapper
        component={<TreeModal target={target} />}
        title="Node"
        icon={"/img/common/modal_buy_icon.png"}
        iconWidth={60}
        iconHeight={59}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        className="modal_node"
      />
      <BinaryContainer>
        <SelectContainer>
          <AutoComplete
            value={value}
            options={options}
            virtual={false}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            filterOption={(inputValue: any, option: any) => {
              return (
                option!.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1 ||
                option!.text.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
              );
            }}
            onChange={onChange}
            placeholder={`Please search...`}
            allowClear={true}
          />
        </SelectContainer>
        <BinaryNode>
          {walletSol.publicKey ? (
            <>
              {Object.keys(binaryData).length > 1 ? (
                <>
                  {treeData && treeData.length > 0 && (
                    <Tree
                      separation={{ siblings: 7, nonSiblings: 7 }}
                      translate={
                        targetSearch
                          ? {
                              x: window.innerWidth / 2 - targetSearch?.x,
                              y: 100 - targetSearch?.y,
                            }
                          : { x: window.innerWidth / 2, y: 100 }
                      }
                      data={treeData}
                      renderCustomNodeElement={(rd3tProps) =>
                        renderRectSvgNode({ ...rd3tProps })
                      }
                      orientation="vertical"
                    />
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <EmptyText text="Please connect wallet to see binary tree" />
          )}
        </BinaryNode>
      </BinaryContainer>
    </>
  );
};

export default BinaryTree;
