import { useSelector } from "react-redux";
import InputCommon from "../../../../components/Common/Input";
import { ButtonCommon } from "../../../../Layout/styled";
import {
  DashboardModal,
  ModalBalanceView,
  ModalInputAmount,
  ModalTextInfo,
} from "../../styled";
import { WicAPI } from "../../../../services/api";
import { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import toast from "react-hot-toast";
import { convertFixed } from "../../../../utils/convertNumber";
import { IsLoadingRedux } from "../../../../redux/slices/user";
import { useDispatch } from "../../../../redux/store";
import LoadingText from "../../../../components/Common/Loading/Text";
import { formatNumberMega } from "../../../../utils/formatNumber";

const ModalSwap = ({ handleCancelSwap }: any) => {
  const walletSol = useWallet();
  const { userProfile, tokenPrice, settings } = useSelector(
    (state: any) => state.user
  );
  const [amountWithdraw, setAmountWithdraw] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const minCoinWithdraw = walletSol.publicKey ? Number(
    settings.filter((setting: any) => setting.name === "Min.Coin.Withdraw")[0]
      .value
  ) : 0;
  const feeCoinWithdraw = walletSol.publicKey ? Number(
    settings.filter((setting: any) => setting.name === "Fee.Withdraw")[0].value
  ) : 0;

  // Sign and validate when withdraw
  const [signatureData, setSignatureData] = useState<any>({});
  const message = new TextEncoder().encode("Confirm withdraw");
  const handleSignWhenWithdraw = async () => {
    setIsPending(true);

    if (!walletSol.publicKey) {
      toast.error("Please connect wallet", { id: "wallet" });
      setIsPending(false);
      return;
    }

    if (amountWithdraw < minCoinWithdraw) {
      toast.error(`Minimum amount is ${minCoinWithdraw}`, { id: "minimum" });
      setIsPending(false);
      return;
    }
    if (amountWithdraw === 0) {
      toast.error("Please enter value", { id: "value" });
      setIsPending(false);
      return;
    }
    if (amountWithdraw > userProfile?.wic_coin) {
      toast.error("Insufficient balance", { id: "balance" });
      setIsPending(false);
      return;
    }

    try {
      const signature =
        walletSol.signMessage && (await walletSol.signMessage(message));
      setSignatureData(signature);
    } catch (error) {
      console.error("Error", error);
      toast.error("Rejected", { id: "rejected" });
      setIsPending(false);
      setSignatureData({});
    }
  };

  const handleWithdraw = async () => {
    if (!walletSol.publicKey) {
      toast.error("Please connect wallet", { id: "wallet" });
      return;
    }

    try {
      const res = await WicAPI.withdraw(
        {
          amount: Number(amountWithdraw),
          address: walletSol?.publicKey?.toString(),
        },
        Buffer.from(signatureData).toString("base64"),
        "Confirm withdraw"
      );
      if (res.status === 202) {
        toast.success("Withdraw success", {
          id: "withdraw",
        });
        dispatch(IsLoadingRedux(true));
        setIsPending(false);
        handleCancelSwap();
        setAmountWithdraw(0);
        setSignatureData({});
      }
    } catch (error) {
      console.log(error);
      toast.error("Withdraw error", { id: "error" });
      setIsPending(false);
      setSignatureData({});
    }
  };

  useEffect(() => {
    if (signatureData && Object.keys(signatureData).length > 0) {
      handleWithdraw();
    }
  }, [signatureData]);

  return (
    <DashboardModal>
      <ModalBalanceView>
        <figure>
          <img
            width={28}
            height={28}
            src="/img/common/modal_balance_icon.png"
            alt="icon"
            loading="lazy"
          />
        </figure>
        <div>
          <div>
            <p>Balance</p>
            <p>
              {userProfile?.wic_coin
                ? formatNumberMega(userProfile?.wic_coin)
                : "0.00"}{" "}
              <span>
                <img
                  width={28}
                  height={27}
                  src="/img/common/wiccoin_icon.png"
                  alt=""
                />
              </span>
            </p>
          </div>
          <p>
            <span>$</span>
            {userProfile?.wic_coin
              ? formatNumberMega(userProfile?.wic_coin * tokenPrice)
              : "0.00"}
          </p>
        </div>
      </ModalBalanceView>
      <ModalInputAmount>
        <p>Amount</p>
        <InputCommon
          type="number"
          placeHolder="Enter Amount"
          suffix={
            <img
              width={28}
              height={27}
              src="/img/common/wiccoin_icon.png"
              alt="icon"
              loading="lazy"
            />
          }
          onKeyDown={(e: any) => {
            if (["e", "E", "-", "+"].includes(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(e: any) => {
            const value = e.target.value;
            const sanitizedValue = value.replace(/[^0-9.]/g, "");
            setAmountWithdraw(sanitizedValue);
          }}
          onPaste={(e: any) => {
            const pasteData = e.clipboardData.getData("text");
            if (!/^[0-9]*\.?[0-9]*$/.test(pasteData)) {
              e.preventDefault();
              toast.error("Please enter a valid positive amount", {
                id: "invalidPaste",
              });
            }
          }}
        />
      </ModalInputAmount>
      <ModalInputAmount>
        <p>Wallet Address</p>
        <InputCommon
          value={
            walletSol.publicKey
              ? walletSol.publicKey?.toString()
              : "Please connect wallet"
          }
          placeHolder="Enter address"
        />
      </ModalInputAmount>
      <ModalTextInfo>
        <p>Min Amount</p>
        <p>
          {settings ? minCoinWithdraw : 0}
          <img
            width={20}
            height={20}
            src="/img/common/wiccoin_icon.png"
            alt="icon"
            loading="lazy"
          />
        </p>
      </ModalTextInfo>
      <ModalTextInfo>
        <p>Fee</p>
        <p>{settings ? <>{feeCoinWithdraw * 100}%</> : <>0</>}</p>
      </ModalTextInfo>
      <ModalTextInfo>
        <p>Swap Fee</p>
        <p>
          {settings ? (
            <>{convertFixed(tokenPrice * amountWithdraw * feeCoinWithdraw)}</>
          ) : (
            <>0</>
          )}
          <img
            width={20}
            height={20}
            src="/img/common/usdt_icon.png"
            alt="icon"
            loading="lazy"
          />
        </p>
      </ModalTextInfo>
      <ModalTextInfo>
        <p>Amount Received</p>
        <p>
          {settings ? (
            <>
              {tokenPrice * amountWithdraw - feeCoinWithdraw > 0
                ? convertFixed(
                    tokenPrice * amountWithdraw -
                      tokenPrice * amountWithdraw * feeCoinWithdraw
                  )
                : 0}
            </>
          ) : (
            <>0</>
          )}

          <img
            width={20}
            height={20}
            src="/img/common/usdt_icon.png"
            alt="icon"
            loading="lazy"
          />
        </p>
      </ModalTextInfo>
      <ButtonCommon
        disabled={
          amountWithdraw === 0 ||
          amountWithdraw > userProfile?.wic_coin ||
          !walletSol.publicKey
            ? true
            : false
        }
        onClick={() => {
          handleSignWhenWithdraw();
        }}
        className="opacity"
      >
        {isPending ? (
          <LoadingText />
        ) : (
          <>
            {!walletSol.publicKey
              ? "Please connect wallet"
              : amountWithdraw === 0
              ? "Please enter value"
              : amountWithdraw > userProfile?.wic_coin
              ? "Not enough balance"
              : "Swap now"}
          </>
        )}
      </ButtonCommon>
    </DashboardModal>
  );
};

export default ModalSwap;
