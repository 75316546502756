import styled from "styled-components";
import { BlockCardLayout } from "../../../Layout/styled";

export const VisualContainer = styled.div`
  position: relative;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid #3d3d3d;
  background: #0c0c0c;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background: url("/img/common/visual_effect.png") no-repeat center / cover;
    width: 100%;
    pointer-events: none;
    height: 100%;
  }
  & > figure {
    position: relative;
    width: max-content;
    margin: 0 auto;
    &::before {
      content: "";
      position: absolute;
      pointer-events: none;
      background: url("/img/common/visual_bg.png") no-repeat center / 100% auto;
      left: 50%;
      margin-left: -278px;
      top: 0;
      width: 556px;
      height: 157px;
    }
  }
  @media screen and (max-width: 767px) {
    border: 0;
    padding: 0;
    background-color: transparent;
    &::before {
      height: 160px;
      background-image: url('/img/common/dot_bg_mobile.png');
    }
  }
`;
export const VisualList = styled.ul`
  display: flex;
  align-items: center;
  gap: 5px;
  & > li {
    position: relative;
    width: 33.33%;
    ${BlockCardLayout} {
      width: auto;
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    & > li {
      width: 100%;
    }
  }
`;
export const VisualHeader = styled.div`
  width: max-content;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    width: calc(100% - 28px);
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
`