import { BlockCardLayout } from "../../../Layout/styled";

const BlockCard = ({
  icon,
  imgWidth,
  imgHeight,
  component,
  className,
}: any) => {
  return (
    <BlockCardLayout className={className}>
      {icon === "" ? (
        <figure>
          <img
            width={44}
            height={36}
            src="/img/common/logo_small.png"
            alt="icon"
            loading="lazy"
          />
        </figure>
      ) : (
        <figure>
          <img
            width={imgWidth}
            height={imgHeight}
            src={icon}
            alt="icon"
            loading="lazy"
          />
        </figure>
      )}
      {component}
    </BlockCardLayout>
  );
};

export default BlockCard;
