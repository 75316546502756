// import { useSelector } from "react-redux";
import ChartCommon from "../../components/Common/Chart";
import InputCommon from "../../components/Common/Input";
import SelectCommon from "../../components/Common/Select";
import { ButtonCommon, FrameBlock } from "../../Layout/styled";
import {
  BuyWicBody,
  BuyWicContainer,
  BuyWicConvert,
  BuyWicHeader,
  BuyWicInput,
  BuyWicLeft,
  BuyWicRight,
  BuyWicSelect,
  PercentageList,
} from "./styled";
import { useEffect, useState } from "react";
import { formatNumberMega } from "../../utils/formatNumber";
import toast from "react-hot-toast";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
  getMint,
} from "@solana/spl-token";
import LoadingText from "../../components/Common/Loading/Text";
import { IsLoadingRedux } from "../../redux/slices/user";
import { useDispatch } from "../../redux/store";
import { convertFixed } from "../../utils/convertNumber";
import { WicAPI } from "../../services/api";
import axios from "axios";
import ModalWrapper from "../../components/Common/Modal";
import WaitingConfirm from "../../components/Common/Modal/WaitingConfirm";

const BuyWic = () => {
  const TOKEN_MINT = new PublicKey(
    "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
  );
  // const TOKEN_MINT = new PublicKey(
  //   "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
  // ); Contract USDT
  const RECIPIENT = new PublicKey(
    "HkUoBmZhjyVHNkKXXkkdoE1sRiPh5cxbSCQHnR74mE75"
  );

  const RECIPIENT1 = new PublicKey(
    "CXt6WYea8pNmmkVKQwSg7jf2zyYaUwdCVvfSaMz2yQcP"
  );
  const RECIPIENT2 = new PublicKey(
    "7XkM2LBVCHa14TFMA4NzT5TJDvtr9CdWFyigJoSG2van"
  );
  const RECIPIENT3 = new PublicKey(
    "6dVGrv97yGU46nnFymffEWLVmTze8tGYC971mpMxV6iY"
  );
  const connection = new Connection(
    "https://green-warmhearted-putty.solana-mainnet.quiknode.pro/530bd2a41cc356062e5cd8b0e69d1fc9bda27d30"
  );
  const selectData = [{ title: "USDT", img: "/img/common/usdt_icon.png" }];
  const [amountBuy, setAmountBuy] = useState("0");
  const { publicKey, sendTransaction } = useWallet();
  const [solBalance, setSolBalance] = useState<number>(0);
  const [tokenBalance, setTokenBalance] = useState<number>(0);
  const [tokenDecimals, setTokenDecimals] = useState<number>(0);
  const [isPending, setPending] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [amountApi, setAmountApi] = useState(0);

  const handleGetTokenBalance = async () => {
    if (!publicKey) {
      return;
    }
    connection.getBalance(publicKey).then((balance) => {
      setSolBalance(balance / LAMPORTS_PER_SOL);
    });
    const tokenAccountAddress = await getAssociatedTokenAddress(
      TOKEN_MINT,
      publicKey
    );
    try {
      const tokenAccount = await getAccount(connection, tokenAccountAddress);
      const mintInfo = await getMint(connection, TOKEN_MINT);
      setTokenDecimals(mintInfo.decimals);
      setTokenBalance(
        Number(tokenAccount.amount) / Math.pow(10, mintInfo.decimals)
      );
    } catch (err) {
      setTokenBalance(0);
    }
  };

  useEffect(() => {
    if (publicKey) {
      handleGetTokenBalance();
    }
  }, [publicKey]);

  const handleChangeValue = () => {};

  const handleEstimatePrice = async () => {
    setPending(true);
    const params = {
      amount: Number(amountBuy),
    };
    try {
      const res = await WicAPI.estimatePrice(params);
      if (res.status === 200) {
        setAmountApi(res.data.amount);
        setPending(false);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
      toast.error("Estimate price error");
      setPending(false);
    }
  };

  const useDebounce = (
    callback: () => void,
    delay: number,
    dependencies: any[]
  ) => {
    useEffect(() => {
      const handler = setTimeout(() => {
        callback();
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [...dependencies, delay]);
  };

  useDebounce(
    () => {
      if (Number(amountBuy) > 0) {
        handleEstimatePrice();
      }
    },
    500,
    [amountBuy]
  );

  useEffect(() => {
    if (!amountBuy || amountBuy === "0") {
      setAmountApi(0);
    }
  }, [amountBuy]);

  const handleSubmitHash = async (hash: any) => {
    try {
      const res = await WicAPI.buyOTC({ tx_hash: hash });
      if (res) {
        toast.success("Buy successfully");
        setTimeout(() => {
          setIsModalOpen(false);
        }, 3000);
      }
    } catch (error) {
      toast.error("Buy error");
      console.log(error);
      setIsModalOpen(false);
    }
  };

  const handleInvestment = async () => {
    if (!publicKey) {
      return;
    }
    if (solBalance < 0.001) {
      setPending(false);
      toast.error("Don't have enough balance for fee", { id: "fee" });
      return;
    }
    if (Number(amountBuy) > tokenBalance * Math.pow(10, tokenDecimals)) {
      setPending(false);
      toast.error("Insufficient balance", { id: "sufficient" });
      return;
    }
    if (Number(amountBuy) === 0) {
      setPending(false);
      toast.error("Invalid value", { id: "value" });
      return;
    }

    setPending(true);
    setIsModalOpen(true);
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd"
      );

      const priceSOL = response.data.solana.usd;
      const feeUSDT = Number(amountBuy) * 0.002;
      const solAmount = feeUSDT / priceSOL;

      const lamportsAmount = Math.round(solAmount * LAMPORTS_PER_SOL);
      const tokenAccountSender: any = await getAssociatedTokenAddress(
        TOKEN_MINT,
        publicKey
      );
      const tokenAccountRecipient = await getAssociatedTokenAddress(
        TOKEN_MINT,
        RECIPIENT
      );
      const transferTokenInstruction = createTransferInstruction(
        tokenAccountSender,
        tokenAccountRecipient,
        publicKey,
        Number(amountBuy) * Math.pow(10, tokenDecimals)
      );
      const transferSolInstruction = SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: RECIPIENT,
        lamports: lamportsAmount,
      });

      const transaction: any = new Transaction().add(
        transferTokenInstruction,
        transferSolInstruction
      );

      const signature = await sendTransaction(transaction, connection);

      const confirmation = await connection.confirmTransaction(
        signature,
        "confirmed"
      );

      if (confirmation.value.err) {
        setPending(false);
        console.log("tx error", confirmation.value.err);
      } else {
        setPending(false);
        handleSubmitHash(signature);
        dispatch(IsLoadingRedux(true));
        handleGetTokenBalance();
      }
    } catch (error) {
      console.error("error:", error);
      toast.error("Transaction rejected");
      setIsModalOpen(false);
    } finally {
      setPending(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ModalWrapper
        className="pending"
        component={<WaitingConfirm />}
        title="Pending Transaction..."
        icon={"/img/common/modal_buy_icon.png"}
        iconWidth={94}
        iconHeight={93}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <BuyWicContainer>
        <BuyWicHeader>
          <h2>Buy WIC (DogWithCap)</h2>
        </BuyWicHeader>
        <BuyWicBody>
          <BuyWicLeft>
            <FrameBlock>
              <ChartCommon />
            </FrameBlock>
          </BuyWicLeft>
          <BuyWicRight>
            <h2>Buy WIC Token</h2>
            <BuyWicSelect>
              <p>Buying with</p>
              <SelectCommon
                disabled
                data={selectData}
                onChange={handleChangeValue}
              />
            </BuyWicSelect>
            <BuyWicInput>
              <div>
                <figure>
                  <img
                    width={16}
                    height={16}
                    src="/img/common/usdt_icon.png"
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                <p>Balance USDT: {convertFixed(tokenBalance)}</p>
              </div>
              <div>
                <p>Buy Amount</p>
                <InputCommon
                  value={amountBuy}
                  placeHolder="0.00"
                  suffix={
                    <p
                      style={{
                        color: "#757575",
                        fontSize: "24px",
                      }}
                    >
                      USDT
                    </p>
                  }
                  onKeyDown={(e: any) => {
                    if (["e", "E", "-", "+"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    const sanitizedValue = value.replace(/[^0-9.]/g, "");
                    setAmountBuy(sanitizedValue);
                  }}
                  onPaste={(e: any) => {
                    const pasteData = e.clipboardData.getData("text");
                    if (!/^[0-9]*\.?[0-9]*$/.test(pasteData)) {
                      e.preventDefault();
                      toast.error("Please enter a valid positive amount", {
                        id: "invalidPaste",
                      });
                    }
                  }}
                />
                <PercentageList>
                  {percentageData.map((item, index) => {
                    return (
                      <li
                        onClick={() => {
                          setAmountBuy(
                            (
                              (item.value * Number(tokenBalance)) /
                              100
                            ).toString()
                          );
                        }}
                        key={index}
                      >
                        {item.value}%
                      </li>
                    );
                  })}
                </PercentageList>
              </div>
            </BuyWicInput>
            <BuyWicConvert>
              <p>You’ll Receive</p>
              <div>
                <p>{formatNumberMega(Number(amountApi))}</p>
                <figure>
                  <img
                    width={33}
                    height={32}
                    src="/img/common/wicdog_icon.png"
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
              </div>
            </BuyWicConvert>
            <ButtonCommon
              disabled={isPending}
              onClick={() => {
                handleInvestment();
              }}
              style={{
                width: "100%",
              }}
              className="opacity"
            >
              {isPending ? <LoadingText /> : "Buy"}
            </ButtonCommon>
          </BuyWicRight>
        </BuyWicBody>
      </BuyWicContainer>
    </>
  );
};

const percentageData = [
  {
    id: 1,
    value: 25,
  },
  {
    id: 2,
    value: 50,
  },
  {
    id: 3,
    value: 75,
  },
  {
    id: 4,
    value: 100,
  },
];
export default BuyWic;
