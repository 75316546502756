import styled from "styled-components";

export const LoadingTextContainer = styled.span`
  font-size: 22px;
  color: #fff;
  position: relative;
  letter-spacing: 4px;
  &::before {
    content: "";
    position: absolute;
    left: 18px;
    bottom: 5px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-bottom-color: #f5c754;
    box-sizing: border-box;
    animation: rotation 0.6s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
