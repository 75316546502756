import { useSelector } from "react-redux";
import InputCommon from "../../../../components/Common/Input";
import { ButtonCommon } from "../../../../Layout/styled";
import {
  DashboardModal,
  ModalBalanceView,
  ModalInputAmount,
  ModalTextInfo,
} from "../../styled";
import { WicAPI } from "../../../../services/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useWallet } from "@solana/wallet-adapter-react";
import { convertFixed } from "../../../../utils/convertNumber";
import { IsLoadingRedux } from "../../../../redux/slices/user";
import { useDispatch } from "../../../../redux/store";
import LoadingText from "../../../../components/Common/Loading/Text";
import { formatNumberMega } from "../../../../utils/formatNumber";

const ModalTransfer = ({ handleCancelTransfer }: any) => {
  const walletSol = useWallet();
  const [amountTransfer, setAmountTransfer] = useState(0);
  const [addressTo, setAddressTo] = useState("");
  const { userProfile, tokenPrice, settings } = useSelector(
    (state: any) => state.user
  );
  const [isConfirmAddress, setIsConfirmAddress] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const minCoinTransfer = walletSol.publicKey ? Number(
    settings.filter((setting: any) => setting.name === "Min.Coin.Transfer")[0]
      .value
  ) : 0;
  const feeCoinTransfer = walletSol.publicKey ? Number(
    settings.filter((setting: any) => setting.name === "Fee.Transfer.Coin")[0]
      .value
  ) : 0;

  const handleCheckUserExist = async () => {
    try {
      const res: any = await WicAPI.checkUserExist(addressTo);
      if (res?.status === 200) {
        setIsConfirmAddress(res?.data.is_exists);
      }
      if (res?.data.is_exists) {
        toast.success("Valid address", { id: "Valid" });
      } else {
        toast.error("Address not found", { id: "NotFound" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (addressTo.length > 35) {
      handleCheckUserExist();
    }
  }, [addressTo]);

  // Sign and validate when transfer
  const [signatureData, setSignatureData] = useState<any>({});
  const message = new TextEncoder().encode("Confirm transfer");
  const handleSignWhenTransfer = async () => {
    setIsPending(true);

    if (!walletSol.publicKey) {
      toast.error("Please connect wallet", { id: "wallet" });
      setIsPending(false);
      return;
    }

    if (amountTransfer < minCoinTransfer) {
      toast.error(`Minimum amount is ${minCoinTransfer}`, { id: "minimum" });
      setIsPending(false);
      return;
    }

    if (amountTransfer < 0) {
      toast.error("Please enter value", { id: "amount" });
      setIsPending(false);
      return;
    }

    if (amountTransfer > userProfile?.wic_coin) {
      toast.error("Insufficient balance", { id: "balance" });
      setIsPending(false);
      return;
    }

    try {
      const signature =
        walletSol.signMessage && (await walletSol.signMessage(message));
      setSignatureData(signature);
    } catch (error) {
      console.error("Error", error);
      toast.error("Rejected", { id: "rejected" });
      setIsPending(false);
      setSignatureData({});
    }
  };

  const handleTransfer = async () => {
    if (!walletSol.publicKey) {
      toast.error("Please connect wallet", { id: "wallet" });
      return;
    }
    try {
      const res = await WicAPI.transfer(
        {
          amount: Number(amountTransfer),
          address: addressTo,
        },
        Buffer.from(signatureData).toString("base64"),
        "Confirm transfer"
      );
      if (res.status === 200) {
        toast.success("Transfer success");
        dispatch(IsLoadingRedux(true));
        setIsPending(false);
        handleCancelTransfer();
        setAmountTransfer(0);
        setAddressTo("");
        setSignatureData({});
      }
    } catch (error) {
      console.log(error);
      toast.error("Error when transfer", { id: "error" });
      setIsPending(false);
    }
  };

  useEffect(() => {
    if (signatureData && Object.keys(signatureData).length > 0) {
      handleTransfer();
    }
  }, [signatureData]);

  return (
    <DashboardModal>
      <ModalBalanceView>
        <figure>
          <img
            width={28}
            height={28}
            src="/img/common/modal_balance_icon.png"
            alt="icon"
            loading="lazy"
          />
        </figure>
        <div>
          <div>
            <p>Balance</p>
            <p>
              {userProfile?.wic_coin
                ? formatNumberMega(userProfile?.wic_coin)
                : "0.00"}{" "}
              <span>
                {" "}
                <img
                  width={28}
                  height={27}
                  src="/img/common/wiccoin_icon.png"
                  alt=""
                />
              </span>
            </p>
          </div>
          <p>
            <span>$</span>
            {userProfile?.wic_coin
              ? formatNumberMega(userProfile?.wic_coin * tokenPrice)
              : "0.00"}
          </p>
        </div>
      </ModalBalanceView>
      <ModalInputAmount>
        <p>Amount</p>
        <InputCommon
          type="number"
          placeHolder="Enter Amount"
          suffix={
            <img
              width={28}
              height={27}
              src="/img/common/wiccoin_icon.png"
              alt="icon"
              loading="lazy"
            />
          }
          onKeyDown={(e: any) => {
            if (["e", "E", "-", "+"].includes(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(e: any) => {
            const value = e.target.value;
            const sanitizedValue = value.replace(/[^0-9.]/g, "");
            setAmountTransfer(sanitizedValue);
          }}
          onPaste={(e: any) => {
            const pasteData = e.clipboardData.getData("text");
            if (!/^[0-9]*\.?[0-9]*$/.test(pasteData)) {
              e.preventDefault();
              toast.error("Please enter a valid positive amount", {
                id: "invalidPaste",
              });
            }
          }}
        />
      </ModalInputAmount>
      <ModalInputAmount>
        <p>Receive Address</p>
        <InputCommon
          placeHolder="Enter address"
          onChange={(e: any) => {
            setAddressTo(e.target.value);
          }}
        />
      </ModalInputAmount>
      <ModalTextInfo>
        <p>Min Amount</p>
        <p>
          {settings ? minCoinTransfer : 0}
          <img
            width={20}
            height={20}
            src="/img/common/wiccoin_icon.png"
            alt="icon"
            loading="lazy"
          />
        </p>
      </ModalTextInfo>
      <ModalTextInfo>
        <p>Fee</p>
        <p>{settings ? feeCoinTransfer * 100 : 0}%</p>
      </ModalTextInfo>
      <ModalTextInfo>
        <p>Transfer Fee</p>
        <p>
          {settings ? convertFixed(amountTransfer * feeCoinTransfer) : 0}{" "}
          <img
            width={20}
            height={20}
            src="/img/common/wiccoin_icon.png"
            alt="icon"
            loading="lazy"
          />
        </p>
      </ModalTextInfo>
      <ModalTextInfo>
        <p>Amount Received</p>
        <p>
          {settings ? (
            <>
              {amountTransfer - amountTransfer * feeCoinTransfer > 0
                ? convertFixed(
                    amountTransfer - amountTransfer * feeCoinTransfer
                  )
                : 0}
            </>
          ) : (
            <>0</>
          )}
          <img
            width={20}
            height={20}
            src="/img/common/wiccoin_icon.png"
            alt="icon"
            loading="lazy"
          />
        </p>
      </ModalTextInfo>
      <ButtonCommon
        disabled={
          amountTransfer < 0 ||
          amountTransfer > userProfile?.wic_coin ||
          !isConfirmAddress ||
          !walletSol.publicKey
            ? true
            : false
        }
        onClick={() => {
          handleSignWhenTransfer();
        }}
        className="opacity"
      >
        {isPending ? (
          <LoadingText />
        ) : (
          <>
            {!walletSol.publicKey
              ? "Please connect wallet"
              : amountTransfer === 0
              ? "Please enter value"
              : amountTransfer > userProfile?.wic_coin
              ? "Not enough balance"
              : !addressTo
              ? "Please enter receive address"
              : !isConfirmAddress
              ? "Address not found"
              : "Transfer now"}
          </>
        )}
      </ButtonCommon>
    </DashboardModal>
  );
};

export default ModalTransfer;
