import { useEffect, useState } from "react";
import { ButtonCommon } from "../../../Layout/styled";
import { ModalInputAmount } from "../../../page/Home/styled";
import { WicAPI } from "../../../services/api";
import InputCommon from "../../Common/Input";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { IsLoadingRedux } from "../../../redux/slices/user";
import { useDispatch } from "../../../redux/store";
import { shortenAddress } from "../../../utils/addressUser";
import { useWallet } from "@solana/wallet-adapter-react";

const SettingUser = () => {
  const { signMessage } = useWallet();
  const { userProfile } = useSelector((state: any) => state.user);
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const dispatch = useDispatch();
  const [isPending, setPending] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Sign and validate when update
  const [signatureData, setSignatureData] = useState<any>({});
  const message = new TextEncoder().encode("Confirm update");
  const handleSignWhenUpdate = async () => {
    setPending(true);

    if (!validateEmail(email) && email.length > 0) {
      toast.error("Invalid email format.", { id: "email" });
      return;
    }

    try {
      const signature = signMessage && (await signMessage(message));
      setSignatureData(signature);
    } catch (error) {
      console.error("Error", error);
      setPending(false);
      setSignatureData({});
    }
  };

  const handleUpdateUser = async () => {
    try {
      const res = await WicAPI.updateUser(
        {
          fullname: fullname !== "" ? fullname : userProfile?.fullname,
          email: email !== "" ? email : userProfile?.email,
        },
        Buffer.from(signatureData).toString("base64"),
        "Confirm update"
      );
      if (res.status === 202) {
        setFullname("");
        setEmail("");
        dispatch(IsLoadingRedux(true));
        toast.success("Update successfully");
        setSignatureData({});
        setPending(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Update error", { id: "error" });
      setPending(false);
      setSignatureData({});
    }
  };

  useEffect(() => {
    if (signatureData && Object.keys(signatureData).length > 0) {
      handleUpdateUser();
    }
  }, [signatureData]);

  return (
    <div>
      <ModalInputAmount>
        <p>
          Full Name:{" "}
          <span
            style={{
              color: "#f68300dd",
            }}
          >
            {!userProfile?.fullname
              ? "Not changed yet"
              : userProfile?.fullname.length > 20
              ? shortenAddress(userProfile?.fullname)
              : userProfile?.fullname}
          </span>
        </p>
        <InputCommon
          placeHolder="Enter Full Name"
          value={fullname}
          onChange={(e: any) => {
            setFullname(e.target.value);
          }}
        />
      </ModalInputAmount>
      <ModalInputAmount>
        <p>
          Email:{" "}
          <span
            style={{
              color: "#f68300dd",
            }}
          >
            {userProfile
              ? userProfile?.address === userProfile?.email
                ? shortenAddress(userProfile?.email)
                : userProfile?.email
              : "--"}
          </span>
        </p>
        <InputCommon
          type="email"
          value={email}
          placeHolder="Enter Email"
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
        />
      </ModalInputAmount>
      <ButtonCommon
        disabled={isPending}
        onClick={() => {
          handleSignWhenUpdate();
        }}
        style={{
          width: "100%",
        }}
        className="opacity"
      >
        SAVE CHANGES
      </ButtonCommon>
    </div>
  );
};

export default SettingUser;
