import styled from "styled-components";

export const ModalBuyContainer = styled.div``;
export const ModalTabsWrapper = styled.div`
  &.staking {
    margin-top: -10px;
  }
  margin-top: -25px;
  h2 {
    color: #dfdfdf;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
  }
`;
export const ModalTabs = styled.ul`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  & > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: 50%;
    border-radius: 4px;
    border: 1px solid rgba(89, 88, 88, 0.4);
    background: #1d1d1d;
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    transition: all 0.15s linear;
    gap: 10px;
    cursor: pointer;
    font-family: "Radio Canada Big", sans-serif;
    &:hover,
    &.active {
      background-color: #fff;
      border-color: #fff;
      p {
        color: #000;
      }
    }
    figure {
      width: 28px;
      height: 27px;
    }
    p {
      color: #fff;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    & > li {
      gap: 5px;
      p {
        font-size: 12px;
      }
    }
  }
`;
export const ModalBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #9f9f9f;
  font-size: 13px;
  margin-bottom: 15px;
  figure {
    width: 14px;
    height: 16px;
  }
`;
export const ModalSelectedData = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 15px;
  & > li {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(50% - 40px);
    padding: 18px;
    border-radius: 6px;
    background: #282828;
    transition: all 0.15s linear;
    cursor: pointer;
    font-family: "Radio Canada Big", sans-serif;
    &:last-child {
      width: 100%;
      justify-content: flex-start;
      gap: 10px;
    }
    &:hover,
    &.active {
      background: #fff;
      & > div {
        h2,
        p {
          color: #000;
        }
        span {
          color: #424242;
        }
      }
    }
    & > div {
      h2,
      span,
      p {
        transition: all 0.15s linear;
      }
      h2,
      p {
        font-size: 16px;
      }
      h2 {
        margin-bottom: 5px;
        color: #fff;
      }
      p {
        margin-bottom: 3px;
        color: #f5c754;
      }
      span {
        color: #fff;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    & > li {
      width: calc(50% - 23px);
      padding: 15px 10px;
      overflow: hidden;
      figure {
        position: absolute;
        right: -10px;
        top: 15px;
      }
      & > div {
        h2,
        p {
          font-size: 14px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
`;
export const ModalAction = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
  input {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #161616;
    border-radius: 5px;
    border: 1px solid #464646;
  }
`
export const ActionCaptcha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 3px;
  background: #FFF;
  color: #000;
  font-size: 15px;
  figure {
    width: 14px;
    height: 18px;
  }
`