import { useContext } from "react";
import { ContextProviderWrapper } from "../../Context";
import { NavigationList, NavigationMobile } from "../styled";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;

  const navigateData = [
    {
      id: 1,
      text: "Home",
      link: "https://app.wicchain.net/",
      icon: "/img/common/home_icon.svg",
    },
    {
      id: 2,
      text: "Dashboard",
      link: "/",
      icon: "/img/common/home_icon.svg",
    },
    {
      id: 3,
      text: "Buy Now",
      link: "/buy-now",
      icon: "/img/common/buyWic_icon.svg",
    },
    {
      id: 4,
      text: isMobile ? "Package" : "Buy Package",
      link: "/buy",
      icon: "/img/common/package_icon.svg",
    },
    // {
    //   id: 5,
    //   text: "Buy WIC",
    //   link: "/buy-wic",
    //   icon: "/img/common/buyWic_icon.svg",
    // },
    {
      id: 5,
      text: isMobile ? "Staking" : "Staking WIC",
      link: "/staking",
      icon: "/img/common/staking_icon.svg",
    },
    {
      id: 6,
      text: "Affiliate",
      link: "/affiliate",
      icon: "/img/common/affiliate_icon.svg",
    },
  ];
  return (
    <>
      {isMobile ? (
        <>
          <NavigationMobile>
            {navigateData.map((item, index) => {
              return (
                <div
                  style={{
                    display: item.id === 1 ? "none" : "",
                  }}
                  key={index}
                >
                  <NavLink to={item.link}>
                    <figure>
                      <img src={item.icon} alt="icon" loading="lazy" />
                    </figure>
                    <p>{item.text}</p>
                  </NavLink>
                </div>
              );
            })}
          </NavigationMobile>
        </>
      ) : (
        <>
          <NavigationList>
            {navigateData.map((item, index) => {
              return (
                <li key={index}>
                  <NavLink to={item.link}>{item.text}</NavLink>
                </li>
              );
            })}
          </NavigationList>
        </>
      )}
    </>
  );
};

export default Navigation;
