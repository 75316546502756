import styled from "styled-components";

export const LayoutMain = styled.div`
  position: relative;
  max-width: 1160px;
  margin: 0 auto;
  padding: 15px 20px;
  /* &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    pointer-events: none;
  }
  &::before {
    background-image: url("/img/common/cube_1.png");
    width: 200px;
    height: 200px;
    top: 700px;
    left: -50px;
  }
  &::after {
    background-image: url("/img/common/cube_2.png");
    width: 200px;
    height: 200px;
    right: -100px;
    top: 300px;
  } */
  @media screen and (max-width: 1023px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 90px;
  }
`;
export const ButtonCommon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 20px;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: #888;
  cursor: pointer;
  border-radius: 8px;
  font-family: "Radio Canada Big", sans-serif;
  transition: all 0.15s linear;
  &.yellow,
  &.opacity:hover,
  &.active {
    color: #fff !important;
    border: 1px solid #e6e5e5 !important;
    background: linear-gradient(180deg, #6e5625 0%, #ecb500 100%) !important;
    box-shadow: 0px 3px 5px 0px rgba(217, 217, 217, 0.9) inset,
      0px -1px 5px 0px rgba(217, 217, 217, 0.9) inset !important;
  }
  &.opacity {
    color: #888;
    border: 1px solid rgba(121, 121, 121, 0.5);
    background: rgba(71, 71, 71, 0.13);
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.2) inset,
      0px -1px 5px 0px rgba(255, 255, 255, 0.2) inset;
    backdrop-filter: blur(2.25px);
  }
  &.error {
    color: #ff3c3c;
    border: 1px solid #383838;
    background: rgba(90, 25, 25, 0.13);
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.1) inset,
      0px -1px 5px 0px rgba(255, 255, 255, 0.1) inset;
    backdrop-filter: blur(2.25px);
    &:hover {
      opacity: 0.7;
    }
  }
  button {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
`;
export const FrameBlock = styled.div`
  padding: 12px 10px;
  border-radius: 8px;
  border: 1px solid #333;
  background: #181818;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    border: 0;
    background-color: transparent;
    h2 {
      font-size: 18px;
    }
  }
`;
export const BlockTitleFlex = styled.div`
  & > figure {
    position: absolute;
    top: 0;
    right: 0;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    & > p {
      &:nth-child(2) {
        justify-content: flex-end;
      }
    }
    &.column {
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
      & > p {
        &:nth-child(2) {
          justify-content: space-between;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;
export const BlockCardLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  width: 165px;
  min-height: 100px;
  padding: 12px;
  border-radius: 8px;
  background: #2c2c2c;
  & > figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 10px;
  }
  ${BlockTitleFlex} {
    & > figure {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &.card_level {
    width: 180px;
  }
  &.level_00 {
    background: url("/img/common/level_bg_00.jpg") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 0;
        right: 0;
        mix-blend-mode: luminosity;
      }
    }
  }
  &.level_0 {
    background: url("/img/common/level_bg_0.jpg") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 0;
        right: 0;
        mix-blend-mode: luminosity;
      }
    }
  }
  &.level_1 {
    background: url("/img/common/level_bg_1.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 0;
        right: 0;
        mix-blend-mode: luminosity;
      }
    }
  }
  &.level_2 {
    background: url("/img/common/level_bg_2.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: plus-lighter;
      }
    }
  }
  &.level_3 {
    background: url("/img/common/level_bg_3.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: screen;
      }
    }
  }
  &.level_4 {
    background: url("/img/common/level_bg_4.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: color-dodge;
      }
    }
  }
  &.level_5 {
    background: url("/img/common/level_bg_5.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: color-dodge;
      }
    }
  }
  &.level_6 {
    background: url("/img/common/level_bg_6.png") no-repeat center / cover;
    ${BlockTitleFlex} {
      & > figure {
        top: 10px;
        right: -10px;
        mix-blend-mode: overlay;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    &.card_level {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    &.card_level {
      width: calc(100% - 24px);
    }
  }
`;

export const BlockTitle = styled.p`
  color: #757575;
  font-size: 12px;
  @media screen and (max-width: 767px) {
    white-space: nowrap;
  }
`;
export const BlockText = styled.p`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: #fff;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    color: #757575;
    font-size: 14px;
  }
`;
export const TitleDecor = styled.h2`
  background: linear-gradient(
    180deg,
    #ffff8f 13.67%,
    #ffe983 30.95%,
    #f8d063 49.4%,
    #ffd600 69.03%,
    #fddb74 92.19%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 33px;
  font-weight: 900;
  line-height: 100%;
  margin-bottom: 15px;
  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
`;
export const TagStatus = styled.div`
  width: max-content;
  margin: 0 auto;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
  color: #000;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  &.success {
    background: linear-gradient(220deg, #8dffcf 11.31%, #19fb9b 83.28%);
  }
  &.pending {
    background: linear-gradient(220deg, #f0e545 11.31%, #bfd212 83.28%);
  }
  &.progress {
    background: linear-gradient(220deg, #eb7b1a 11.31%, #f76917 83.28%);
  }
`;
export const TableItemMobile = styled.div``;
export const TableItemLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  gap: 10px;
  & > p {
    font-size: 15px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  & > span {
    color: #757575;
    font-size: 14px;
  }
  ${TagStatus} {
    margin: 0;
  }
`;
export const TableItemButton = styled.div`
  padding: 6px;
  border-radius: 6px;
  border: 1px solid rgba(82, 82, 82, 0.4);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(41, 41, 41, 0.22) 100%
  );
  box-shadow: 0px 32.62px 32.62px 0px rgba(0, 0, 0, 0.3);
  color: #757575;
  text-align: center;
  font-size: 13px;
`;
export const TableItemCollpase = styled.div`
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
  padding-top: 6px;
  transition: all 0.25s linear;
  height: 0;
  overflow: hidden;
  &.open {
    height: 110px;
  }
`;
export const AddressTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  figure {
    width: 12px;
    height: 15px;
    cursor: pointer;
    transition: all 0.15s linear;
    &:hover {
      opacity: 0.7;
    }
  }
`;
export const FrameBlockSmall = styled.div`
  display: flex;
  margin-bottom: 15px;
  gap: 5px;
  ${BlockCardLayout} {
    width: 50%;
  }
`;
export const BorderAnimation = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 8px;
  filter: drop-shadow(0 0 10px #fff);
  opacity: 0.5;
  animation: RotateLine 5s linear infinite, borderOpacity 3s linear infinite;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 3px;
    background: conic-gradient(
      from calc(var(--angle) + var(--start-angle)),
      transparent 0,
      #e4c113 100%,
      transparent 25%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: RotateLine 5s linear infinite, borderOpacity 3s linear infinite;
  }
  @keyframes RotateLine {
    to {
      --angle: 360deg;
    }
  }
  @keyframes borderOpacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;
