import InputCommon from "../../../../components/Common/Input";
import SelectCommon from "../../../../components/Common/Select";
import { ButtonCommon } from "../../../../Layout/styled";
import { FilterContainer } from "./styled";

const FilterTransaction = () => {
  const filterStatus = [
    {
      title: "All status",
      img: "",
    },
    {
      title: "Done",
      img: "",
    },
    {
      title: "Fail",
      img: "",
    },
  ];
  const filterKind = [
    {
      title: "All kind",
      img: "",
    },
    {
      title: "WIC",
      img: "",
    },
    {
      title: "USDT",
      img: "",
    },
  ];
  // const filterWallet = [
  //   {
  //     title: "All wallet",
  //     img: "",
  //   },
  //   {
  //     title: "Solana",
  //     img: "",
  //   },
  // ];
  return (
    <FilterContainer>
      <InputCommon
        placeHolder="Search"
        suffix={
          <img
            width={13}
            height={14}
            src="/img/common/search_icon.png"
            alt="icon"
            loading="lazy"
          />
        }
      />
      <SelectCommon data={filterStatus} />
      <SelectCommon data={filterKind} />
      {/* <SelectCommon data={filterWallet} /> */}
      <ButtonCommon className="opacity">Save Changes</ButtonCommon>
    </FilterContainer>
  );
};

export default FilterTransaction;
