import { useContext, useState } from "react";
import ModalWrapper from "../../components/Common/Modal";
import TableCommon from "../../components/Common/Table";
import VisualFlex from "../../components/Common/Visual";
import {
  ButtonCommon,
  FrameBlock,
  TableItemButton,
  TableItemCollpase,
  TableItemLine,
  TableItemMobile,
  TagStatus,
  TitleDecor,
} from "../../Layout/styled";
import { TransactionTable } from "../Home/Transaction/styled";
import ModalBuy from "./Modal";
import { BuyContainer, BuyHeader } from "./styled";
import { ContextProviderWrapper } from "../../components/Context";
import { useSelector } from "react-redux";
import moment from "moment";
import { convertFixed } from "../../utils/convertNumber";
import { shortenAddress } from "../../utils/addressUser";
import { Link } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";

const BuyPackage = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const { userProfile, transaction } = useSelector((state: any) => state.user);
  const [clickedMobile, setClickedMobile] = useState("");
  const walletSol: any = useWallet();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const sortedTransactions = Array.isArray(transaction)
    ? [...transaction]
        .filter((item: any) => item.type === "PACKAGE")
        .sort(
          (a: any, b: any) =>
            moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
        )
    : [];

  // Calculate total amount
  const totalOrderAmount = sortedTransactions.reduce(
    (acc: number, item: any) => acc + Number(item.amount || 0),
    0
  );

  const headingData = isMobile
    ? [
        {
          name: "",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TableItemMobile>
                <TableItemLine>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    {convertFixed(data.token_value)}{" "}
                    <span
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      {data.method_payment === "LOCAL" ? (
                        <img
                          width={20}
                          height={20}
                          src="/img/common/wiccoin_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      ) : (
                        <img
                          width={20}
                          height={20}
                          src="/img/common/wicdog_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      )}
                    </span>
                  </p>
                  <p>{shortenAddress(data.transaction_id)}</p>
                </TableItemLine>
                <TableItemLine>
                  <p>{data.amount}</p>
                  <p>{moment(data.created_at).format("D/M/YYYY")}</p>
                </TableItemLine>
                <TableItemCollpase
                  className={
                    data.transaction_id === clickedMobile ? "open" : ""
                  }
                >
                  <TableItemLine>
                    <span>Type</span>
                    <p>{data.type}</p>
                  </TableItemLine>
                  <TableItemLine>
                    <span>Status</span>
                    <TagStatus
                      className={
                        data.status === "PENDING" ? "pending" : "success"
                      }
                    >
                      {data.status}
                    </TagStatus>
                  </TableItemLine>
                  <TableItemLine>
                    <span>Description</span>
                    <p>{data.description}</p>
                  </TableItemLine>
                  <TableItemLine>
                    <span>Hash</span>
                    {data.tx_hash ? (
                      <Link
                        style={{
                          color: "#00aaff",
                        }}
                        to={`https://solscan.io/tx/${data.tx_hash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {shortenAddress(data.tx_hash)}
                      </Link>
                    ) : (
                      <p>--</p>
                    )}
                  </TableItemLine>
                </TableItemCollpase>
                <TableItemButton
                  onClick={() => {
                    setClickedMobile(data.transaction_id);
                  }}
                >
                  {clickedMobile ? "Show less" : "Click to see more"}
                </TableItemButton>
              </TableItemMobile>
            );
          },
        },
      ]
    : [
        {
          name: "ID",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{shortenAddress(data.transaction_id)}</p>;
          },
        },
        {
          name: `Token Amount`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                {data.token_value ? (
                  <>
                    {data.method_payment === "LOCAL"
                      ? convertFixed(data.token_value)
                      : convertFixed(data.onchain_value)}{" "}
                    <span
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      {data.method_payment === "LOCAL" ? (
                        <img
                          width={20}
                          height={20}
                          src="/img/common/wiccoin_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      ) : (
                        <img
                          width={20}
                          height={20}
                          src="/img/common/wicdog_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      )}
                    </span>
                  </>
                ) : (
                  "0"
                )}
              </p>
            );
          },
        },
        {
          name: `Package Amount`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>${convertFixed(data.amount)}</p>;
          },
        },
        {
          name: `Type`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{data.type}</p>;
          },
        },
        {
          name: `Description`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{data.description}</p>;
          },
        },
        {
          name: `Hash`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <>
                {data.tx_hash ? (
                  <Link
                    style={{
                      color: "#00aaff",
                    }}
                    to={`https://solscan.io/tx/${data.tx_hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {shortenAddress(data.tx_hash)}
                  </Link>
                ) : (
                  <p>--</p>
                )}
              </>
            );
          },
        },
        {
          name: `Data Time`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <div>{moment(data.created_at).format("D/M/YYYY HH:mm:ss")}</div>
            );
          },
        },
        {
          name: `Status`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TagStatus
                className={data.status === "PENDING" ? "pending" : "success"}
              >
                {data.status}
              </TagStatus>
            );
          },
        },
      ];

  const dataVisual = [
    {
      id: 1,
      name: "Total Order",
      value: `$${convertFixed(totalOrderAmount)}`,
      icon: "/img/common/order_icon.svg",
    },
    {
      id: 2,
      name: "Total Profit",
      value: `${
        userProfile?.total_profit_package
          ? convertFixed(userProfile?.total_profit_package)
          : 0
      }`,
      icon: "/img/common/chart_icon.svg",
      isLogoTitle: "/img/common/wiccoin_icon.png",
    },
    {
      id: 3,
      name: "Current Package",
      value: `${
        userProfile?.package
          ? userProfile?.package === "Saturin"
            ? "Saturn"
            : userProfile?.package
          : "Not yet"
      }`,
      icon: "/img/common/buypackage_icon.svg",
    },
  ];

  return (
    <>
      <ModalWrapper
        component={<ModalBuy handleCancel={handleCancel} />}
        title="Buy Package"
        icon={"/img/common/modal_buy_icon.png"}
        iconWidth={94}
        iconHeight={93}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <BuyContainer>
        {!isMobile && (
          <BuyHeader>
            <TitleDecor>BUY PACKAGE</TitleDecor>
            <ButtonCommon
              onClick={() => {
                if (userProfile && walletSol?.publicKey) {
                  setIsModalOpen(true);
                }
              }}
              className="opacity"
            >
              {userProfile && walletSol?.publicKey
                ? "Buy Now"
                : "Please connect wallet"}
            </ButtonCommon>
          </BuyHeader>
        )}
        <VisualFlex
          data={dataVisual}
          icon={"/img/common/visual_1.png"}
          widthIcon={119}
          heightIcon={116}
          titleDecorMobile="BUY PACKAGE"
          textButtonMobile="BUY NOW"
          functionMobile={() => {
            setIsModalOpen(true);
          }}
        />
        <FrameBlock>
          <h2>Order History</h2>
          <TransactionTable>
            <TableCommon
              data={transaction?.length > 0 ? sortedTransactions : []}
              heading={headingData}
            />
          </TransactionTable>
        </FrameBlock>
      </BuyContainer>
    </>
  );
};

export default BuyPackage;
