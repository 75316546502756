import styled from "styled-components";
import { SelectContainer } from "../../components/Common/Select/styled";
import { InputContainer } from "../../components/Common/Input/styled";

export const BuyWicContainer = styled.div`
  position: relative;
  z-index: 5;
`;
export const BuyWicHeader = styled.div`
  text-align: center;
  margin-bottom: 40px;
  p {
    position: relative;
    color: #fff;
    font-size: 14px;
    letter-spacing: 4px;
    width: max-content;
    margin: 0 auto;
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: no-repeat center / 100% auto;
      top: 50%;
      margin-top: -10px;
      width: 10px;
      height: 16px;
    }
    &::before {
      background-image: url("/img/BuyWic/buywic_title_decor_1.svg");
      left: -17px;
    }
    &::after {
      background-image: url("/img/BuyWic/buywic_title_decor_2.svg");
      right: -15px;
    }
  }
  h2 {
    font-size: 50px;
    font-weight: 600;
    background: linear-gradient(
      270deg,
      #fedf7a 0%,
      #ecb131 27.45%,
      #ffff8f 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0.28px;
  }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 30px;
    }
  }
`;
export const BuyWicBody = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const BuyWicLeft = styled.div`
  max-width: 700px;
  width: 100%;
  flex-shrink: 0;
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`;
export const BuyWicRight = styled.div`
  width: 100%;
  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }
`;
export const BuyWicSelect = styled.div`
  margin-bottom: 15px;
  & > p {
    color: #dfdfdf;
    font-size: 14px;
    letter-spacing: 0.14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  ${SelectContainer} {
    .ant-select {
      border-radius: 5px;
      border: 1px solid #464646;
      background: #161616;
      height: 40px;
    }
  }
`;
export const BuyWicInput = styled.div`
  padding: 12px;
  border-radius: 8px;
  background: #2c2c2c;
  backdrop-filter: blur(3);
  margin-bottom: 5px;

  & > div {
    p {
      color: #757575;
      font-size: 12px;
    }
    &:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      & > figure {
        width: 16px;
        height: 16px;
      }
    }
  }
  ${InputContainer} {
    .ant-input-affix-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
    input {
      font-size: 24px;
    }
  }
`;
export const PercentageList = styled.ul`
  display: flex;
  align-items: center;
  gap: 5px;
  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding: 5px 8px;
    border-radius: 4px;
    border: 1px solid rgba(80, 80, 80, 0.4);
    background: rgba(40, 40, 40, 0.7);
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.15s linear;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
`;
export const BuyWicConvert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 98px;
  border-radius: 8px;
  margin-bottom: 20px;
  background: url("/img/BuyWic/bg_received.jpg") no-repeat center / cover;
  backdrop-filter: blur(3px);
  & > p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 10px;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    figure {
      width: 32px;
      height: 32px;
    }
    p {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
    }
  }
`;
export const BuyWicInfo = styled.div`
  margin: 16px 0;
  & > p {
    color: #f0fdf2;
    font-size: 16px;
    font-weight: 500;
  }
`;
