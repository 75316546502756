import styled from "styled-components";

export const InvitersTableContainer = styled.div`
  @media screen and (max-width: 767px) {
    .ant-table-row {
      .ant-table-cell {
        &:not(:last-child) {
          width: 50% !important;
        }
      }
    }
  }
`;
