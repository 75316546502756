import styled from "styled-components";
import { SelectContainer } from "../Select/styled";

export const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
`;
export const ChartMain = styled.div`
  position: relative;
`;
export const ChartData = styled.div`
  p {
    &:nth-child(1) {
      font-size: 20px;
      color: #757575;
    }
    &:nth-child(2) {
      color: #FFF;
      font-size: 26px;
    }
  }
  span {
    display: block;
    color: #9F9F9F;
    font-size: 13px;
    &.color {
      background: linear-gradient(180deg, #dfdfdf 0%, rgb(170, 220, 186) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:nth-child(even) {
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    p {
      &:nth-child(1) {
        font-size: 15px;
      }
      &:nth-child(2) {
        font-size: 16px;
      }
    }
  }
`
export const ChartFilterBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  & > div {
    h4 {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
`
export const ChartFilterButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 5px 8px;
    border-radius: 4px;
    border: 1px solid rgba(75, 75, 75, 0.2);
    background: rgba(40, 40, 40, 0.70);
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    font-size: 12px;
    color: #FFF;
    transition: all .15s linear;
    text-transform: capitalize;
    cursor: pointer;
    &:hover,
    &.active {
      border-color: #BBF7C6;
      background: radial-gradient(105.75% 89.98% at 2.11% 14.58%, rgba(129, 238, 151, 0.00) 0%, rgba(129, 238, 151, 0.20) 100%), rgba(35, 35, 35, 0.50);
      box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.60) inset, 0px 8px 24px -16px rgba(0, 0, 0, 0.40);
    }
  }
`;
export const TooltipChart = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  pointer-events: none;
  white-space: nowrap;
  display: none;
  z-index: 100;
  font-size: 12px;
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  ${SelectContainer} {
    width: 100px;
    .ant-select {
      height: 30px;
      border-radius: 5px;
    }
    .ant-select-selection-item {
      div p {
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    display: block;
  }
`
export const ChartSelected = styled.ul`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #0C0E0E;
  padding: 5px;
  gap: 5px;
  figure {
    width: 15px;
    height: 15px;
    opacity: .5;
    transition: all .15s linear;
  }
  & > li {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 8px 10px;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: all .15s linear;
    cursor: pointer;
    p {
      color: #9F9F9F;
      font-size: 14px;
      transition: all .15s linear;
    }
    &.active,
    &:hover {
      border-color: rgba(255, 255, 255, 0.10);
      background: rgba(40, 40, 40, 0.70);
      background-blend-mode: luminosity;
      box-shadow: 1.8px 3.6px 14.4px 0px rgba(248, 248, 248, 0.06) inset;
      backdrop-filter: blur(45px);
      p {
        color: #fff;
      }
      figure {
        opacity: 1;
      }
    }
  }
`
export const ChartToken = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 50px;
  height: 50px; */
  /* border-radius: 15px; */
  /* border: 2px solid rgba(85, 85, 85, 0.4);
  background: linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, rgba(255, 255, 255, 0.22) 100%);
  box-shadow: 0px 43px 43px 0px rgba(0, 0, 0, 0.30);
  backdrop-filter: blur(2px); */
  /* @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
  } */
`
