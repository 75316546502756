import React, { useState, useEffect } from "react";
import {
  Connection,
  PublicKey,
  Transaction,
  LAMPORTS_PER_SOL,
  SystemProgram
} from "@solana/web3.js";
import {
  getAssociatedTokenAddress,
  getAccount,
  createTransferInstruction,
  getMint,
} from "@solana/spl-token";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import axios from "axios";

const TOKEN_MINT = new PublicKey(
  "AEZrKseocU3kL4Gjym6tx4PML3SFX6f3F16VK1cJDRT1"
);
const RECIPIENT = new PublicKey("CXt6WYea8pNmmkVKQwSg7jf2zyYaUwdCVvfSaMz2yQcP");
const connection = new Connection(
  "https://green-warmhearted-putty.solana-mainnet.quiknode.pro/530bd2a41cc356062e5cd8b0e69d1fc9bda27d30"
);

const TransferToken: React.FC = () => {
  const { publicKey, sendTransaction } = useWallet();
  const [amount, setAmount] = useState<number>(100);
  const [solBalance, setSolBalance] = useState<number>(0);
  const [tokenBalance, setTokenBalance] = useState<number>(0);
  const [tokenDecimals, setTokenDecimals] = useState<number>(0);
  const [isPending, setPending] = useState<boolean>(false);

  useEffect(() => {
    if (publicKey) {
      connection.getBalance(publicKey).then((balance) => {
        setSolBalance(balance / LAMPORTS_PER_SOL);
      });

      (async () => {
        const tokenAccountAddress = await getAssociatedTokenAddress(
          TOKEN_MINT,
          publicKey
        );
        try {
          const tokenAccount = await getAccount(
            connection,
            tokenAccountAddress
          );
          const mintInfo = await getMint(connection, TOKEN_MINT);
          setTokenDecimals(mintInfo.decimals);
          setTokenBalance(
            Number(tokenAccount.amount) / Math.pow(10, mintInfo.decimals)
          );
        } catch (err) {
          setTokenBalance(0);
        }
      })();
    }
  }, [publicKey]);

  const handleInvestment = async () => {
    if (!publicKey) {
      return;
    }

    setPending(true);

    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd"
      );

      const priceSOL = response.data.solana.usd;
      const feeUSDT = amount * 0.002;
      const solAmount = feeUSDT / priceSOL;

      console.log('response', response);
      console.log('priceSOL', priceSOL);
      console.log('solAmount', solAmount);
      const lamportsAmount = Math.round(solAmount * LAMPORTS_PER_SOL);
      const tokenAccountSender = await getAssociatedTokenAddress(TOKEN_MINT, publicKey);
      const tokenAccountRecipient = await getAssociatedTokenAddress(TOKEN_MINT, RECIPIENT);
      const transferTokenInstruction = createTransferInstruction(
        tokenAccountSender,
        tokenAccountRecipient,
        publicKey,
        amount * Math.pow(10, tokenDecimals)
      );

      const transferSolInstruction = SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: RECIPIENT,
        lamports: lamportsAmount,
      });

      const transaction = new Transaction().add(
        transferTokenInstruction,
        transferSolInstruction
      );

      const signature = await sendTransaction(transaction, connection);
      const confirmation = await connection.confirmTransaction(signature, "confirmed");

      if (confirmation.value.err) {
        setPending(false);
        console.log("tx error", confirmation.value.err);
      } else {
        setPending(false);
        console.log("txHash", signature);
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setPending(false);
    }
  };

  return (
    <div>
      <WalletMultiButton />
      {publicKey && (
        <div>
          <p style={{ color: "white" }}>Balabce SOL: {solBalance.toFixed(4)}</p>
          <p style={{ color: "white" }}>
            Balance Token: {tokenBalance.toFixed(tokenDecimals)}
          </p>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
            placeholder="0.00"
          />
          <button
            onClick={handleInvestment}
            disabled={isPending || amount <= 0}
          >
            {isPending ? "Investing" : "Investment"}
          </button>
        </div>
      )}
    </div>
  );
};

export default TransferToken;
