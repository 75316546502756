import styled from "styled-components";
import { BlockCardLayout, FrameBlock } from "../../../Layout/styled";

export const NetworkContainer = styled.div``;
export const NetworkBody = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  ${FrameBlock} {
    &:nth-child(1) {
      max-width: 684px;
    }
    &:nth-child(2) {
      width: 430px;
      flex-shrink: 0;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    ${FrameBlock} {
      &:nth-child(2) {
        width: 340px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    ${FrameBlock} {
      &:nth-child(2) {
        width: 100%;
        flex-shrink: unset;
      }
    }
  }
`;
export const NetworkList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 710px;
  gap: 8px;
  margin-bottom: 20px;
  & > li {
    width: calc(33.33% - 6px);
    ${BlockCardLayout} {
      width: auto;
      background: #222222;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    & > li {
      width: calc(50% - 5px);
      ${BlockCardLayout} {
        width: auto;
      }
    }
  }
`;
export const ListTransaction = styled.ul`
  & > li {
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    padding: 16px;
    background-color: #1f1f1f;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;
export const TransactionLeft = styled.div`
  & > p {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #fcfcfd;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      span {
        color: #f5c754;
      }
    }
    &:nth-child(2) {
      display: flex;
      gap: 5px;
      /* color: #8d8b85; */
      font-size: 14px;
    }
  }
`;
export const TransactionRight = styled.div`
  font-size: 12px;
  text-align: right;
  span {
    color: #8d8b85;
  }
  p {
    color: #f5c754;
  }
`;
export const TransactionTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  color: #000;
  font-size: 11px;
  border-radius: 4px;
  background: linear-gradient(180deg, #7a7a7a 0%, #e0e0e0 100%);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
  margin-left: 5px;
`;
