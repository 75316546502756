import { SwitchTabWrapper } from "./styled";

const SwitchTab = ({ dataTabs, switchTabFunction, tabClicked }: any) => {
  return (
    <SwitchTabWrapper>
      <ul>
        {dataTabs.map((item: any, index: any) => {
          return (
            <li
              className={item.id === tabClicked ? "active" : ""}
              onClick={() => switchTabFunction(item.id)}
              key={index}
            >
              {item.tabName}
            </li>
          );
        })}
      </ul>
    </SwitchTabWrapper>
  );
};

export default SwitchTab;
