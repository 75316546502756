import { TreeModalContainer } from "./styled";
import { ModalInputAmount } from "../../../Home/styled";
import InputCommon from "../../../../components/Common/Input";
import BlockCard from "../../../../components/Common/BlockCard";
import {
  BlockText,
  BlockTitle,
  BlockTitleFlex,
} from "../../../../Layout/styled";
import { shortenAddress } from "../../../../utils/addressUser";
import CardLevel from "../../../../components/Common/CardLevel";

const TreeModal = ({ target }: any) => {
  return (
    <TreeModalContainer>
      <CardLevel target={target} />
      <ModalInputAmount>
        <p>Account ID</p>
        <InputCommon value={target.attributes.accountID} />
      </ModalInputAmount>
      <ModalInputAmount>
        <p>Ranking</p>
        <InputCommon value={target.attributes.leadership_level} />
      </ModalInputAmount>
      <ModalInputAmount>
        <p>Left branch revenue</p>
        <InputCommon value={target.attributes.left_revenue} />
      </ModalInputAmount>
      <ModalInputAmount>
        <p>Right branch revenue</p>
        <InputCommon value={target.attributes.right_revenue} />
      </ModalInputAmount>
    </TreeModalContainer>
  );
};

export default TreeModal;
