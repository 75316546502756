import styled from "styled-components";
import { VisualContainer } from "../../components/Common/Visual/styled";

export const StakingContainer = styled.div`
  position: relative;
  z-index: 5;
  ${VisualContainer} {
    & > figure {
      margin-top: -15px;
    }
  }
`;
