import styled from "styled-components";
import { TitleDecor } from "../../Layout/styled";

export const BuyContainer = styled.div`
  position: relative;
  z-index: 5;
`;
export const BuyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  ${TitleDecor} {
    margin-bottom: 0;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
    flex-direction: column;
    position: relative;
  }
`;
