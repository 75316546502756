import styled from "styled-components";

export const BinaryListContainer = styled.div`
  display: flex;
  gap: 5px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const LeftTeam = styled.div`
  width: 50%;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
export const RightTeam = styled.div`
  width: 50%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const LeftTeamHeader = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: #2c2c2c;
  margin-bottom: 5px;
`;
export const TeamHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 25px;
  figure {
    height: 20px;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid rgba(86, 86, 86, 0.4);
    background: rgba(248, 248, 248, 0.05);
    background-blend-mode: luminosity;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.3);
    img {
      mix-blend-mode: luminosity;
    }
  }
  p {
    color: #FFF;
    font-size: 16px;
  }
`;
export const TeamInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  & > div {
    p {
      &:nth-child(1) {
        color: #757575;
        font-size: 12px;
        margin-bottom: 5px;
      }
      &:nth-child(2) {
        color: #fff;
        font-size: 16px;
      }
    }
  }
`;
