import styled from "styled-components";

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-right: 30px;
  pointer-events: none;
  margin-bottom: 10px;
  &::before {
    content: "";
    position: absolute;
    background: url('/img/common/modal_decor.png') no-repeat center / 100% auto;
    width: 371px;
    height: 146px;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
  }
`;
