import styled from "styled-components";
import { BlockCardLayout, ButtonCommon } from "../../Layout/styled";
import { InputContainer } from "../../components/Common/Input/styled";

export const DashboardContainer = styled.div`
  position: relative;
  z-index: 2;
`;
export const CardInfo = styled.div`
  display: flex;
  gap: 5px;
  @media screen and (min-width: 1024px) and (max-width: 1160px) {
    .special {
      flex-shrink: 0;
    }
  }
  @media screen and (min-width: 901px) and (max-width: 1023px) {
    ${BlockCardLayout} {
      width: 130px;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const CardDescription = styled.div`
  display: flex;
  gap: 5px;
  @media screen and (min-width: 768px) and (max-width: 900px) {
    flex-wrap: wrap;
    width: 90%;
    .special {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    .special {
      width: auto;
      gap: 30px;
    }
  }
`;
export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media screen and (min-width: 768px) and (max-width: 900px) {
    flex-direction: row;
    width: 100%;
    ${BlockCardLayout} {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: unset;
    ${BlockCardLayout} {
      width: 50%;
    }
  }
`;
export const CardAction = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  background-color: #0c0c0c;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    pointer-events: none;
    left: 0;
  }
  &::before {
    background-image: url("/img/Dashboard/linegrahp_decor.png");
    width: 450px;
    height: 87px;
    bottom: 0;
  }
  &::after {
    background-image: url("/img/Dashboard/light_decor.png");
    width: 356px;
    height: 144px;
    top: 0;
  }
  & > figure {
    position: relative;
    width: 82px;
    margin: 20px auto 0;
    &::before {
      content: "";
      position: absolute;
      top: -160px;
      right: -160px;
      width: 210px;
      height: 160px;
      background-color: #ffd581;
      filter: blur(162px);
    }
  }
  @media screen and (min-width: 320px) and (max-width: 1160px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: 767px) {
    width: auto;
    & > figure {
      margin-top: 0;
    }
  }
`;
export const FrameBlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  h2 {
    margin-bottom: 0;
  }
`;
export const CardButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  ${ButtonCommon} {
    width: 50%;
  }
`;
export const DashboardModal = styled.div`
  ${ButtonCommon} {
    width: 100%;
  }
`;
export const ModalInputAmount = styled.div`
  textarea {
    color: #fff;
  }
  .ant-input-affix-wrapper .ant-input {
    &:hover,
    &:focus {
      background: transparent;
    }
  }
  .ant-input-affix-wrapper,
  input,
  textarea,
  .ant-input-outlined:hover {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #161616 !important;
    border-radius: 5px;
    border: 1px solid #464646;
  }
  & > p {
    color: #dfdfdf;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  & > ${InputContainer}, textarea {
    margin-bottom: 12px;
  }
`;
export const ModalBalanceView = styled.div`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #474747;
  background: rgba(75, 75, 75, 0.4);
  backdrop-filter: blur(6px);
  margin-bottom: 15px;
  & > div {
    & > p {
      text-align: right;
      font-size: 14px;
      color: #fff;
      span {
        color: #757575;
      }
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      & > p {
        font-size: 14px;
        &:nth-child(1) {
          color: #757575;
        }
        &:nth-child(2) {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #fff;
          span {
            width: 28px;
            height: 27px;
          }
        }
      }
    }
  }
`;
export const ModalTextInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  & > p {
    color: #fff;
    &:nth-child(1) {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #ffd581;
    }
  }
`;
