import { Outlet, useParams } from "react-router-dom";
import { LayoutMain } from "./styled";
import Navigation from "../components/Header/Navigation";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../components/Context";
import { useWallet } from "@solana/wallet-adapter-react";
import { WicAPI } from "../services/api";
import toast from "react-hot-toast";
import { decode } from "bs58";
import nacl from "tweetnacl";
import { useDispatch } from "../redux/store";
import axios from "axios";
import {
  getTokenPrice,
  getTransaction,
  getWicPrice,
  IsLoadingRedux,
  Settings,
  UserProfile,
} from "../redux/slices/user";
import { useSelector } from "react-redux";
import { useWalletMultiButton } from "@solana/wallet-adapter-base-ui";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";

const LayoutContainer = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const walletSol: any = useWallet();
  const dispatch = useDispatch();
  // Catch ref
  const { ref } = useParams();
  const [signatureData, setSignatureData] = useState<any>({});
  const message = new TextEncoder().encode("Verify");
  const { isLoading, userProfile } = useSelector((state: any) => state.user);
  const { setVisible: setModalVisible } = useWalletModal();
  const { onDisconnect } = useWalletMultiButton({
    onSelectWallet() {
      setModalVisible(false);
    },
  });

  async function signMessage() {
    const isCheck = await handleCheckUserExist();

    if (walletSol.publicKey && !isCheck) {
      try {
        const signature = await walletSol.signMessage(message);
        setSignatureData(signature);
      } catch (error) {
        console.error("Error", error);
      }
    }
  }

  useEffect(() => {
    if (walletSol.publicKey) {
      localStorage.setItem("publickey", walletSol?.publicKey.toString());
      signMessage();
    }
  }, [walletSol.publicKey]);

  useEffect(() => {
    if (
      signatureData &&
      Object.keys(signatureData).length > 0 &&
      walletSol.publicKey
    ) {
      verifySignature(walletSol.publicKey.toString(), signatureData);
    }
  }, [signatureData]);

  async function verifySignature(publicKey: any, signature: any) {
    try {
      const publicKeyUint8: any = decode(publicKey);
      const signatureUint8 = new Uint8Array(signature);
      const isValid = nacl.sign.detached.verify(
        message,
        signatureUint8,
        publicKeyUint8
      );
      if (isValid) {
        await handleLogin();
      } else {
        console.log("Invalid signature");
      }
    } catch (error) {
      console.error("Error during signature verification:", error);
    }
  }

  const handleSettings = async () => {
    try {
      const res = await WicAPI.settings();
      if (res) {
        dispatch(Settings(res?.data?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserInfo = async () => {
    try {
      const res = await WicAPI.userInfo();
      if (res) {
        dispatch(UserProfile(res?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckUserExist = async () => {
    try {
      const res: any = await WicAPI.checkUserExist(
        walletSol.publicKey.toString()
      );
      if (res?.data.is_exists) {
        setSignatureData({});
        await handleLogin();
      }
      //  else {
      //   if (onDisconnect) {
      //     onDisconnect();
      //     dispatch(UserProfile({}));
      //   }
      // }
      return res.data.is_exists;
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetTransaction = async () => {
    try {
      const res = await WicAPI.transaction();
      if (res) {
        dispatch(getTransaction(res?.data?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogin = async () => {
    try {
      const res: any = await WicAPI.authLogin({
        public_key: walletSol.publicKey.toString(),
        message: "Verify",
        signature:
          Object.keys(signatureData).length < 1
            ? ""
            : Buffer.from(signatureData).toString("base64"),
        referral_code: !ref ? "" : ref,
      });
      if (res) {
        setSignatureData({});
        await handleUserInfo();
        await handleSettings();
        await handleGetTokenPrice();
        await handleGetTransaction();
        await handleGetWicPrice();
        toast.success("Login success", { id: "SuccessLogin" });
      }
    } catch (error: any) {
      console.log(error);
      if (onDisconnect) {
        onDisconnect();
        dispatch(UserProfile({}));
      }
      if (error.error.data.errorKey === "auth.accountLocked") {
        toast.error("This account has been blocked", { id: "errorLogin" });
      } else {
        toast.error("Error when login", { id: "errorLogin" });
      }
    }
  };

  useEffect(() => {
    if (walletSol.disconnecting) {
      localStorage.removeItem("token");
      setSignatureData({});
      dispatch(UserProfile({}));
    }
  }, [walletSol.disconnecting]);

  // Get token price
  const handleGetTokenPrice = async () => {
    try {
      const res: any = await WicAPI.tokenPrice();
      if (res) {
        dispatch(getTokenPrice(res?.data.price.value));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetWicPrice = async () => {
    try {
      const res = await axios.get(
        "https://api.geckoterminal.com/api/v2/simple/networks/solana/token_price/3S2rq1PpUbHkLP1WrFbupiqo2U4cvk58BziaEpw7umQC"
      );
      if (res) {
        dispatch(
          getWicPrice(
            res?.data?.data?.attributes?.token_prices[
              "3S2rq1PpUbHkLP1WrFbupiqo2U4cvk58BziaEpw7umQC"
            ]
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleGetWicPrice();
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // Call again when some action is execute
    const handleRefetchData = async () => {
      await handleUserInfo();
      await handleGetTransaction();
      await dispatch(IsLoadingRedux(false));
    };
    if (isLoading) {
      handleRefetchData();
    }
  }, [isLoading]);

  // Handle Lock wallet
  useEffect(() => {
    if (userProfile?.is_lock) {
      toast.error("This account has been blocked");
      if (onDisconnect) {
        localStorage.removeItem("token");
        setSignatureData({});
        dispatch(UserProfile({}));
        onDisconnect();
      }
    }
  }, [userProfile]);

  return (
    <LayoutMain>
      {isMobile && <Navigation />}
      <Outlet />
    </LayoutMain>
  );
};

export default LayoutContainer;
