import { Modal } from "antd";
import { ModalHeader } from "./styled";

const ModalWrapper = ({
  component,
  title,
  icon,
  iconWidth,
  iconHeight,
  isModalOpen,
  handleOk,
  handleCancel,
  className
}: any) => {
  return (
    <Modal
      className={className ? `modal-wrapper ${className}` : `modal-wrapper`}
      footer={false}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <ModalHeader>
        <h2>{title}</h2>
        <figure>
          <img
            width={iconWidth}
            height={iconHeight}
            src={icon}
            alt="icon"
            loading="lazy"
          />
        </figure>
      </ModalHeader>
      {component}
    </Modal>
  );
};

export default ModalWrapper;
