import { Link } from "react-router-dom";
import { HeaderActions, HeaderContainer, HeaderInner } from "./styled";
import { UnifiedWalletButton, useWallet } from "@jup-ag/wallet-adapter";
import { ContextProviderWrapper } from "../Context";
import { useContext, useEffect, useState } from "react";
import Navigation from "./Navigation";
import Wallet from "./ConnectWallet";
import ModalWrapper from "../Common/Modal";
import SettingUser from "./SettingUser";
import YourWallet from "../Common/YourWallet";
import { ButtonCommon } from "../../Layout/styled";
import { shortenAddress } from "../../utils/addressUser";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const Header = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalWalletOpen, setIsModalWalletOpen] = useState(false);
  const { userProfile } = useSelector((state: any) => state.user);

  const walletSol = useWallet();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkWallet = () => {
    setIsModalWalletOpen(false);
  };

  const handleCancelWallet = () => {
    setIsModalWalletOpen(false);
  };

  useEffect(() => {
    if (userProfile && userProfile?.fullname === null && walletSol.connected) {
      setTimeout(() => {
        setIsModalOpen(true);
        toast.success("Please set your Full Name")
      }, 1500)
    }
  },[userProfile, walletSol.connected])

  return (
    <>
      <HeaderContainer>
        <HeaderInner>
          <Link to="https://wicchain.net/">
            <img
              width={118}
              height={38}
              src="/img/common/logo.png"
              alt="icon"
              loading="lazy"
            />
          </Link>
          {!isMobile && <Navigation />}
          <HeaderActions>
            {walletSol.publicKey && (
              <figure
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <img
                  width={16}
                  height={16}
                  src="/img/common/setting_icon.svg"
                  alt="icon"
                  loading="lazy"
                />
              </figure>
            )}
            {!walletSol.publicKey ? (
              <>
                <UnifiedWalletButton
                  buttonClassName="custom"
                  overrideContent={<Wallet />}
                />
              </>
            ) : (
              <>
                <ButtonCommon
                  onClick={() => {
                    setIsModalWalletOpen(true);
                  }}
                  className="opacity"
                >
                  {userProfile ? (
                    <>
                      {!userProfile?.fullname
                        ? shortenAddress(walletSol.publicKey.toString())
                        : userProfile?.fullname}
                    </>
                  ) : (
                    "--"
                  )}
                </ButtonCommon>
              </>
            )}
          </HeaderActions>
        </HeaderInner>
      </HeaderContainer>
      <ModalWrapper
        component={<SettingUser />}
        title="Account Setting"
        icon={"/img/common/setting_modal_icon.png"}
        iconWidth={55}
        iconHeight={56}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <ModalWrapper
        className="modal_wallet"
        component={<YourWallet handleCancelWallet={handleCancelWallet} />}
        title="Your Wallet"
        icon={"/img/common/your_wallet_icon.png"}
        iconWidth={55}
        iconHeight={56}
        isModalOpen={isModalWalletOpen}
        handleOk={handleOkWallet}
        handleCancel={handleCancelWallet}
      />
    </>
  );
};

export default Header;
