import { useContext, useEffect, useState } from "react";
import ModalWrapper from "../../components/Common/Modal";
import TableCommon from "../../components/Common/Table";
import VisualFlex from "../../components/Common/Visual";
import {
  ButtonCommon,
  FrameBlock,
  TagStatus,
  TitleDecor,
} from "../../Layout/styled";
import { BuyHeader } from "../Buy/styled";
import { TransactionTable } from "../Home/Transaction/styled";
import ModalStaking from "./Modal";
import { StakingContainer } from "./styled";
import { ContextProviderWrapper } from "../../components/Context";
import { WicAPI } from "../../services/api";
import { useSelector } from "react-redux";
import { shortenAddress } from "../../utils/addressUser";
import { convertFixed } from "../../utils/convertNumber";
import moment from "moment";

const Staking = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [transactionStaking, setTransactionStaking] = useState<any>();
  const { userProfile } = useSelector((state: any) => state.user);
  const headingData = [
    {
      name: "ID",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return <p>{shortenAddress(data.staking_id)}</p>;
      },
    },

    {
      name: `Amount`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            {convertFixed(data.amount)}
            <img
              width={20}
              height={20}
              src="/img/common/wiccoin_icon.png"
              alt="icon"
              loading="lazy"
            />
          </p>
        );
      },
    },
    {
      name: `Profit (%)`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return <p>{data.profit_percent}%</p>;
      },
    },
    {
      name: `Total Profit (WIC)`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            {convertFixed(data.total_profit)}
            <img
              width={20}
              height={20}
              src="/img/common/wiccoin_icon.png"
              alt="icon"
              loading="lazy"
            />
          </p>
        );
      },
    },
    {
      name: `Create At`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return <p>{moment(data.created_at).format("D/M/YYYY HH:mm:ss")}</p>;
      },
    },
    {
      name: `Finish At`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return <p>{moment(data.finish_at).format("D/M/YYYY HH:mm:ss")}</p>;
      },
    },
    {
      name: `Status`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <TagStatus
            className={data.status === "IN_PROGRESS" ? "progress" : "success"}
          >
            {data.status === "IN_PROGRESS" ? "Blocked" : "Claim"}
          </TagStatus>
        );
      },
    },
  ];

  const dataVisual = [
    {
      id: 1,
      name: "Total Staking",
      value: `${
        transactionStaking?.total_staking
          ? convertFixed(transactionStaking?.total_staking)
          : 0
      }`,
      icon: "/img/common/plus_icon.svg",
      isLogoTitle: "/img/common/wiccoin_icon.png",
    },
    {
      id: 2,
      name: "Total Profit",
      value: `${
        transactionStaking?.total_profit
          ? convertFixed(transactionStaking?.total_profit)
          : 0
      }`,
      icon: "/img/common/chart_icon.svg",
      isLogoTitle: "/img/common/wiccoin_icon.png",
    },
    {
      id: 3,
      name: "Total Withdraw",
      value: `${
        transactionStaking?.total_withdraw
          ? convertFixed(transactionStaking?.total_withdraw)
          : 0
      }`,
      icon: "/img/common/download_icon.svg",
      isLogoTitle: "/img/common/wiccoin_icon.png",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleStakingTable = async () => {
    try {
      const res = await WicAPI.stakingHistory();
      if (res.status === 200) {
        setTransactionStaking(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userProfile) {
      handleStakingTable();
    }
  }, [userProfile]);

  return (
    <>
      <ModalWrapper
        component={<ModalStaking handleCancel={handleCancel} />}
        title="Staking Package"
        icon={"/img/common/modal_staking_icon.png"}
        iconWidth={82}
        iconHeight={69}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <StakingContainer>
        {!isMobile && (
          <BuyHeader>
            <TitleDecor>STAKING WICCOIN</TitleDecor>
            <ButtonCommon
              onClick={() => {
                setIsModalOpen(true);
              }}
              className="opacity"
            >
              STAKING NOW
            </ButtonCommon>
          </BuyHeader>
        )}
        <VisualFlex
          data={dataVisual}
          icon={"/img/common/visual_2.png"}
          widthIcon={172}
          heightIcon={136}
          titleDecorMobile="STAKING WICCOIN"
          textButtonMobile="STAKING NOW"
          functionMobile={() => {
            setIsModalOpen(true);
          }}
        />
        <FrameBlock>
          <h2>Staking List</h2>
          <TransactionTable>
            <TableCommon
              data={transactionStaking?.data}
              heading={headingData}
            />
          </TransactionTable>
        </FrameBlock>
      </StakingContainer>
    </>
  );
};

export default Staking;
