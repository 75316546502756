import styled from "styled-components";

export const BinaryContainer = styled.div``;

export const BinaryNode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  height: 700px;
  .rd3t-link {
    stroke: #b7b7b7;
    stroke-width: 3px;
  }
  @media screen and (max-width: 767px) {
    height: 500px;
  }
`;
export const NodeImage = styled.div`
  width: max-content;
  height: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(248, 248, 248, 0.05);
  background-blend-mode: luminosity;
  box-shadow: 0px 4.571px 27.429px 0px rgba(0, 0, 0, 0.3);
  img {
    width: 22px;
    height: 18px;
  }
`;
export const NodeInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const NodeProfile = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: #fff;
    &:not(:last-child) {
      margin-bottom: 2px;
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      gap: 5px;
      img {
        width: 32px;
        height: 31px;
        border-radius: 50%;
        opacity: 0.8;
      }
    }
  }
`;
