import { useContext, useState } from "react";
import InputCommon from "../../../components/Common/Input";
import SelectCommon from "../../../components/Common/Select";
import TableCommon from "../../../components/Common/Table";
import { HistoryFilter, TransactionTable } from "./styled";
import { ContextProviderWrapper } from "../../../components/Context";
import { useSelector } from "react-redux";
import { shortenAddress } from "../../../utils/addressUser";
import { convertFixed } from "../../../utils/convertNumber";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  TableItemButton,
  TableItemCollpase,
  TableItemLine,
  TableItemMobile,
  TagStatus,
} from "../../../Layout/styled";

const DashboardHistory = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const { transaction = [], userProfile } = useSelector(
    (state: any) => state.user
  );
  const [clickedMobile, setClickedMobile] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All Status");
  const [selectedType, setSelectedType] = useState("All Type");

  const filterStatus = [
    {
      title: "All Status",
      img: "",
    },
    {
      title: "SUCCESS",
      img: "",
    },
    {
      title: "PENDING",
      img: "",
    },
  ];
  const filterType = [
    {
      title: "All Type",
      img: "",
    },
    {
      title: "PROFIT_DAILY",
      img: "",
    },
    {
      title: "PACKAGE",
      img: "",
    },
    {
      title: "WITHDRAW",
      img: "",
    },
  ];

  const handleConvertType = (type: any) => {
    switch (type) {
      case "PACKAGE":
        return "Buy Package";
      case "PROFIT_DAILY":
        return "Profit Daily";
      case "BRANCH_BONUS":
        return "Branch Bonus";
      case "WITHDRAW":
        return "Withdraw";
      case "DIRECT_COMMISSION":
        return "Direct Commission";
      case "GROWTH_COMMISSION":
        return "Growth Commission";
      case "INTEREST_ON_INTEREST":
        return "Reward on Commission";
      case "BUY_TOKEN_OTC":
        return "Buy WIC OTC";
      default:
        break;
    }
  };

  const headingData = isMobile
    ? [
        {
          name: "",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TableItemMobile>
                <TableItemLine>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    {data.token_value}{" "}
                    <span
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      {data.method_payment === "LOCAL" ||
                      (!data.method_payment &&
                        data.type !== "BUY_TOKEN_OTC") ? (
                        <img
                          width={20}
                          height={20}
                          src="/img/common/wiccoin_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      ) : (
                        <img
                          width={20}
                          height={20}
                          src="/img/common/wicdog_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      )}
                    </span>
                  </p>
                  <p>{shortenAddress(data.transaction_id)}</p>
                </TableItemLine>
                <TableItemLine>
                  <p>{data.amount}</p>
                  <p>{moment(data.created_at).format("D/M/YYYY")}</p>
                </TableItemLine>
                <TableItemCollpase
                  className={
                    clickedMobile === data.transaction_id ? "open" : ""
                  }
                >
                  <TableItemLine>
                    <span>Type</span>
                    <p>{handleConvertType(data.type)}</p>
                  </TableItemLine>
                  <TableItemLine>
                    <span>Status</span>
                    <TagStatus
                      className={
                        data.status === "PENDING" ? "pending" : "success"
                      }
                    >
                      {data.status}
                    </TagStatus>
                  </TableItemLine>
                  <TableItemLine>
                    <span>Description</span>
                    <p>{data.description}</p>
                  </TableItemLine>
                  <TableItemLine>
                    <span>Hash</span>
                    {data.tx_hash ? (
                      <Link
                        to={`https://solscan.io/tx/${data.tx_hash}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "#00aaff",
                        }}
                      >
                        {shortenAddress(data.tx_hash)}
                      </Link>
                    ) : (
                      <p>--</p>
                    )}
                  </TableItemLine>
                </TableItemCollpase>
                <TableItemButton
                  onClick={() => {
                    setClickedMobile(data.transaction_id);
                  }}
                >
                  {clickedMobile === data.transaction_id
                    ? "Show less"
                    : "Click to see more"}
                </TableItemButton>
              </TableItemMobile>
            );
          },
        },
      ]
    : [
        {
          name: "ID",
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{shortenAddress(data.transaction_id)}</p>;
          },
        },
        {
          name: `Amount`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                {data.token_value ? (
                  <>
                    {data.method_payment === "LOCAL" || !data.method_payment
                      ? convertFixed(data.token_value)
                      : convertFixed(data.onchain_value)}{" "}
                    <span
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      {data.method_payment === "LOCAL" ||
                      (!data.method_payment &&
                        data.type !== "BUY_TOKEN_OTC") ? (
                        <img
                          width={20}
                          height={20}
                          src="/img/common/wiccoin_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      ) : (
                        <img
                          width={20}
                          height={20}
                          src="/img/common/wicdog_icon.png"
                          alt="icon"
                          loading="lazy"
                        />
                      )}
                    </span>
                  </>
                ) : (
                  "0"
                )}
              </p>
            );
          },
        },
        {
          name: `Type`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return <p>{handleConvertType(data.type)}</p>;
          },
        },
        {
          name: `Description`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <p
                style={{
                  display: "block",
                  width: "240px",
                }}
              >
                {data.description}
              </p>
            );
          },
        },
        {
          name: `Hash`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <>
                {data.tx_hash ? (
                  <Link
                    style={{
                      color: "#00aaff",
                    }}
                    to={`https://solscan.io/tx/${data.tx_hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {shortenAddress(data.tx_hash)}
                  </Link>
                ) : (
                  <p>--</p>
                )}
              </>
            );
          },
        },
        {
          name: `Data Time`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <div>{moment(data.created_at).format("D/M/YYYY HH:mm:ss")}</div>
            );
          },
        },
        {
          name: `Status`,
          dataIndex: "",
          key: "",
          render: (data: any) => {
            return (
              <TagStatus
                className={data.status === "PENDING" ? "pending" : "success"}
              >
                {data.status}
              </TagStatus>
            );
          },
        },
      ];

  // Sort the transactions by created_at in descending order
  const sortedTransactions = Array.isArray(transaction)
    ? [...transaction].sort(
        (a: any, b: any) =>
          moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
      )
    : [];

  // Filter transactions based on the search term, selected status, and type
  const filteredTransactions = sortedTransactions.filter((txn: any) => {
    const matchesUser =
      userProfile?.user_id === txn.current_user || txn.type === "TRANSFER";
    const matchesSearch = txn.description
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesStatus =
      selectedStatus === "All Status" || txn.status === selectedStatus;
    const matchesType =
      selectedType === "All Type" || txn.type === selectedType;
    return matchesUser && matchesSearch && matchesStatus && matchesType;
  });

  return (
    <div>
      {!isMobile && (
        <HistoryFilter>
          <InputCommon
            placeHolder="Search"
            suffix={
              <img
                width={13}
                height={14}
                src="/img/common/search_icon.png"
                alt="icon"
                loading="lazy"
              />
            }
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
          <SelectCommon
            data={filterStatus}
            onChange={(e: any) => setSelectedStatus(e)}
          />
          <SelectCommon
            data={filterType}
            onChange={(e: any) => setSelectedType(e)}
          />
        </HistoryFilter>
      )}
      <TransactionTable>
        <TableCommon data={filteredTransactions || []} heading={headingData} />
      </TransactionTable>
    </div>
  );
};

export default DashboardHistory;
