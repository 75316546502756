import { NodeImage, NodeInfo, NodeProfile } from "../Tree/styled";
import { shortenAddress } from "../../../utils/addressUser";
import { EllipsisOutlined } from "@ant-design/icons";
import { NodeLevel, NodeMenu, NodeWrapper } from "./styled";
import { useEffect } from "react";
import { BorderAnimation } from "../../../Layout/styled";
import { isIOS } from "react-device-detect";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const NodeItem = ({
  nodeDatum,
  toggleNode,
  isLeftNodePosition,
  setTarget,
  setIsModalOpen,
  setTargetSearch,
  hierarchyPointNode,
  value,
}: any) => {
  useEffect(() => {
    if (hierarchyPointNode.data.attributes?.fullname === value) {
      setTargetSearch(hierarchyPointNode);
    }
  }, [value]);

  const handleChangeName = () => {
    switch (nodeDatum?.attributes.package?.name) {
      case "Activated":
        return "level_0";
      case "Run":
        return "level_1";
      case "Venus":
        return "level_2";
      case "Jupiter":
        return "level_3";
      case "Mercury":
        return "level_4";
      case "Mars":
        return "level_5";
      case "Saturin":
        return "level_6";
      default:
        return "level_00";
    }
  };

  const handleChangeIOS = () => {
    switch (nodeDatum?.attributes.package?.name) {
      case "Activated":
        return "/img/Common/graph_chart_0.jpg";
      case "Run":
        return "/img/Common/graph_chart_01.jpg";
      case "Venus":
        return "/img/Common/graph_chart_02.jpg";
      case "Jupiter":
        return "/img/Common/graph_chart_03.jpg";
      case "Mercury":
        return "/img/Common/graph_chart_04.jpg";
      case "Mars":
        return "/img/Common/graph_chart_05.jpg";
      case "Saturin":
        return "/img/Common/graph_chart_06.jpg";
      default:
        return "/img/Common/graph_chart_00.jpg";
    }
  };

  // For IOS
  const foreignObjectProps = {
    width: "350",
    height: "120",
    x: isLeftNodePosition ? -390 : 40,
    y: -50,
  };

  const handleChangeColor = () => {
    switch (nodeDatum?.attributes.package?.name) {
      case "Activated":
        return "#fff";
      case "Run":
        return "#81c8ff";
      case "Venus":
        return "#fff";
      case "Jupiter":
        return "#d6a7ff";
      case "Mercury":
        return "#ffc9c4";
      case "Mars":
        return "#ffe89d";
      case "Saturin":
        return "#ffd0c0";
      default:
        return "#fff";
    }
  };

  return (
    <>
      {isIOS ? (
        <>
          <svg {...foreignObjectProps}>
            <defs>
              <pattern
                id="backgroundImage"
                patternUnits="userSpaceOnUse"
                width="100%"
                height="100%"
              >
                <image href={handleChangeIOS()} width="100%" height="100%" />
              </pattern>
            </defs>
            <rect
              width="350"
              height="120"
              fill="url(#backgroundImage)"
              rx="10"
            />
            <g
              onClick={(e) => {
                setTarget(nodeDatum);
                setIsModalOpen(true);
                e.stopPropagation();
              }}
            >
              <rect
                width="20"
                height="20"
                fill="white"
                x="320"
                y="30"
                rx="10"
              />
              <text x="325" y="43" fontSize="12px" fill="black">
                •••
              </text>
            </g>

            <image
              href="/img/common/logo_small.png"
              x="10"
              y="35"
              height="40"
              width="40"
              style={{ mixBlendMode: "luminosity" }}
            />
            <text x="60" y="40" fontSize="12" fill="white">
              Name:{" "}
              {nodeDatum?.attributes.fullname === nodeDatum?.attributes.address
                ? shortenAddress(nodeDatum?.attributes.fullname)
                : nodeDatum?.attributes.fullname}
            </text>
            <text x="60" y="60" fontSize="12" fill="white">
              Address: {shortenAddress(nodeDatum?.attributes.address)}
            </text>
            <text x="60" y="80" fontSize="12" fill="white">
              Total Invest: $
              {nodeDatum?.attributes?.package
                ? nodeDatum?.attributes?.total_package_usd
                : "0"}
            </text>
            <g>
              <text
                x="265"
                y="80"
                fontSize="15px"
                fontWeight="600"
                fill={handleChangeColor()}
              >
                {nodeDatum?.attributes?.package
                  ? nodeDatum?.attributes?.package?.name
                  : ""}
              </text>
            </g>
          </svg>
        </>
      ) : (
        <foreignObject
          x={isLeftNodePosition ? "-390" : "40"}
          y={isLeftNodePosition ? "-50" : "-50"}
          width="350"
          height="120"
        >
          <NodeWrapper
            className={handleChangeName()}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <BorderAnimation
              style={{
                visibility:
                  hierarchyPointNode.data.attributes?.fullname === value
                    ? "visible"
                    : "hidden",
              }}
            />
            <NodeMenu
              onClick={(e: any) => {
                setTarget(nodeDatum);
                setIsModalOpen(true);
                e.stopPropagation();
              }}
            >
              <EllipsisOutlined />
            </NodeMenu>
            <NodeInfo>
              <NodeImage>
                <img
                  style={{
                    mixBlendMode: "luminosity",
                  }}
                  src="/img/common/logo_small.png"
                  alt="avatar"
                />
              </NodeImage>
              <NodeProfile>
                <p>
                  Name:{" "}
                  {nodeDatum?.attributes.fullname ===
                  nodeDatum?.attributes.address
                    ? shortenAddress(nodeDatum?.attributes.fullname)
                    : nodeDatum?.attributes.fullname}
                </p>
                <p>
                  Address: {shortenAddress(nodeDatum?.attributes.address)}
                  <CopyToClipboard
                    text={nodeDatum?.attributes.address}
                    onCopy={() => toast.success("Coppied !")}
                  >
                    <figure
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <img
                        width={42}
                        height={41}
                        src="/img/common/copy.jpg"
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                  </CopyToClipboard>
                </p>
                <p>
                  Total Invest:
                  <span>
                    {" "}
                    $
                    {!nodeDatum?.attributes?.package
                      ? "0"
                      : nodeDatum?.attributes?.total_package_usd}
                  </span>
                </p>
              </NodeProfile>
            </NodeInfo>
            <NodeLevel>
              <p>
                {nodeDatum?.attributes?.package
                  ? nodeDatum?.attributes?.package?.name
                  : ""}
              </p>
            </NodeLevel>
          </NodeWrapper>
        </foreignObject>
      )}
    </>
  );
};

export default NodeItem;
