import { useEffect, useState } from "react";
import {
  ModalBalance,
  ModalBuyContainer,
  ModalSelectedData,
  ModalTabs,
  ModalTabsWrapper,
} from "./styled";
import { ButtonCommon } from "../../../Layout/styled";
import toast from "react-hot-toast";
import { WicAPI } from "../../../services/api";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  Transaction,
} from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
  getMint,
} from "@solana/spl-token";
import LoadingText from "../../../components/Common/Loading/Text";
import { useSelector } from "react-redux";
import { convertFixed } from "../../../utils/convertNumber";
import { useDispatch } from "../../../redux/store";
import { IsLoadingRedux } from "../../../redux/slices/user";
import Typewriter from "typewriter-effect";

const TOKEN_MINT = new PublicKey(
  "3S2rq1PpUbHkLP1WrFbupiqo2U4cvk58BziaEpw7umQC"
);
const RECIPIENT = new PublicKey("oDos2MwNemsQN9MKzQQMBo6zrCJr7fYYisTpj96Vqtd");
const connection = new Connection(
  "https://green-warmhearted-putty.solana-mainnet.quiknode.pro/530bd2a41cc356062e5cd8b0e69d1fc9bda27d30"
);

const ModalBuy = ({ handleCancel }: any) => {
  const { publicKey, sendTransaction, signMessage } = useWallet();
  const walletSol: any = useWallet();
  const [solBalance, setSolBalance] = useState<number>(0);
  const [tokenBalance, setTokenBalance] = useState<number>(0);
  const [tokenDecimals, setTokenDecimals] = useState<number>(0);
  const [isPending, setPending] = useState<boolean>(false);
  const [tabSelect, setTabSelect] = useState(1);
  const [valueSelect, setValueSelect] = useState(0);
  const dispatch = useDispatch();
  const { wicPrice, tokenPrice, userProfile } = useSelector(
    (state: any) => state.user
  );

  const tabData = [
    {
      id: 1,
      icon: "/img/common/wicdog_icon.png",
      name: "WIC (DogWithCap)",
    },
    {
      id: 2,
      icon: "/img/common/wiccoin_icon.png",
      name: "WICCOIN",
    },
  ];

  const selectedData = [
    {
      id: 1,
      icon: "/img/common/data_1.png",
      title: "Saturn (S)",
      package: "Saturn",
      price: "10,000 USDT",
      approx: "≈ 746,527,534,760.52 WIC",
      value: 10000,
    },
    {
      id: 2,
      icon: "/img/common/data_2.png",
      title: "Mars (M)",
      package: "Mars",
      price: "5,000 USDT",
      approx: "≈ 746,527,534,760.52 WIC",
      value: 5000,
    },
    {
      id: 3,
      icon: "/img/common/data_3.png",
      title: "Mercury (MS)",
      package: "Mercury",
      price: "3,000 USDT",
      approx: "≈ 746,527,534,760.52 WIC",
      value: 3000,
    },
    {
      id: 4,
      icon: "/img/common/data_4.png",
      title: "Jupiter (J)",
      package: "Jupiter",
      price: "2,000 USDT",
      approx: "≈ 746,527,534,760.52 WIC",
      value: 2000,
    },
    {
      id: 5,
      icon: "/img/common/data_5.png",
      title: "Venus (V)",
      package: "Venus",
      price: "1,000 USDT",
      approx: "≈ 746,527,534,760.52 WIC",
      value: 1000,
    },
    {
      id: 6,
      icon: "/img/common/data_6.png",
      title: "Run",
      package: "Run",
      price: "100 USDT",
      approx: "≈ 746,527,534,760.52 WIC",
      value: 100,
    },
    {
      id: 7,
      icon: "/img/common/data_6.png",
      title: "Activated",
      package: "Activated",
      price: "25 USDT",
      approx: "≈ 746,527,534,760.52 WIC",
      value: 25,
    },
  ];

  const handleGetBalanceWicOnChain = async () => {
    if (!publicKey) {
      return;
    }
    connection.getBalance(publicKey).then((balance) => {
      setSolBalance(balance / LAMPORTS_PER_SOL);
    });
    const tokenAccountAddress = await getAssociatedTokenAddress(
      TOKEN_MINT,
      publicKey
    );
    try {
      const tokenAccount = await getAccount(connection, tokenAccountAddress);
      const mintInfo = await getMint(connection, TOKEN_MINT);
      setTokenDecimals(mintInfo.decimals);
      setTokenBalance(
        Number(tokenAccount.amount) / Math.pow(10, mintInfo.decimals)
      );
    } catch (err) {
      setTokenBalance(0);
    }
  };

  // Get token balance
  useEffect(() => {
    if (publicKey) {
      handleGetBalanceWicOnChain();
    }
  }, [publicKey]);

  useEffect(() => {
    if (!publicKey) {
      setTokenBalance(0);
      setTokenDecimals(0);
    }
  }, [publicKey]);

  // Sign and validate when buy
  const [signatureData, setSignatureData] = useState<any>({});
  const message = new TextEncoder().encode("Confirm buy");
  const handleSignWhenBuy = async () => {
    setPending(true);
    const amountInTokens =
      (Number(valueSelect) / Number(wicPrice)) *
      1.01 *
      Math.pow(10, tokenDecimals);

    if (valueSelect === 0) {
      toast.error("Please select your package", { id: "empty" });
      setPending(false);
      return;
    }

    if (tabSelect === 1) {
      if (solBalance < 0.001) {
        setPending(false);
        toast.error("Don't have enough balance for fee", { id: "fee" });
        return;
      }
      if (amountInTokens > tokenBalance * Math.pow(10, tokenDecimals)) {
        setPending(false);
        toast.error("Insufficient balance", { id: "sufficient" });
        return;
      }
    } else {
      if (Number(valueSelect) / Number(tokenPrice) > userProfile?.wic_coin) {
        setPending(false);
        toast.error("Insufficient balance", { id: "sufficient" });
        return;
      }
    }

    if (!publicKey) {
      toast.error("Please connect wallet", { id: "wallet" });
      return;
    }

    try {
      const signature = signMessage && (await signMessage(message));
      setSignatureData(signature);
    } catch (error) {
      console.error("Error", error);
      setPending(false);
      setSignatureData({});
    }
  };

  const handleInvestment = async () => {
    if (!publicKey) {
      toast.error("Please connect wallet");
      return;
    }

    if (signatureData && Object.keys(signatureData).length > 0) {
      if (tabSelect === 1) {
        const amountInTokens =
          (Number(valueSelect) / Number(wicPrice)) *
          Math.pow(10, tokenDecimals) *
          1.01;

        try {
          const tokenAccountSender = await getAssociatedTokenAddress(
            TOKEN_MINT,
            publicKey
          );
          const tokenAccountRecipient = await getAssociatedTokenAddress(
            TOKEN_MINT,
            RECIPIENT
          );

          const transferAmount = BigInt(Math.floor(amountInTokens));

          const transaction = new Transaction().add(
            createTransferInstruction(
              tokenAccountSender,
              tokenAccountRecipient,
              publicKey,
              transferAmount
            )
          );

          const signature = await sendTransaction(transaction, connection);
          const confirmation = await connection.confirmTransaction(
            signature,
            "confirmed"
          );

          if (confirmation.value.err && !signature) {
            setPending(false);
            setSignatureData({});
            console.log("tx error", confirmation.value.err);
          } else {
            await handleSubmitBuyOnChain(signature);
            await handleGetBalanceWicOnChain();
            setSignatureData({});
            setPending(false);
            dispatch(IsLoadingRedux(true));
          }
        } catch (error: any) {
          setPending(false);
          toast.error(error?.error?.message || "Transaction failed");
          console.error("Error:", error);
          setSignatureData({});
        }
      } else {
        handleSubmitBuy();
      }
    }
  };

  useEffect(() => {
    if (signatureData && Object.keys(signatureData).length > 0) {
      handleInvestment();
    }
  }, [signatureData]);

  const handleSubmitBuy = async () => {
    try {
      const res = await WicAPI.buyPackage(
        {
          amount: Number(valueSelect),
        },
        Buffer.from(signatureData).toString("base64"),
        "Confirm buy"
      );
      if (res.status === 202) {
        toast.success("Buy successfully");
        setPending(false);
        handleCancel();
        dispatch(IsLoadingRedux(true));
        setSignatureData({});
        setValueSelect(0);
      }
    } catch (error: any) {
      setPending(false);
      console.log(error);
      toast.error(
        error?.error?.data?.errorKey === "package.match.invalid"
          ? "Package invalid"
          : "error"
      );
    }
  };

  const handleSubmitBuyOnChain = async (txHash: any) => {
    try {
      const res = await WicAPI.buyPackageOnChain(
        {
          tx_hash: txHash,
        },
        Buffer.from(signatureData).toString("base64"),
        "Confirm buy"
      );
      if (res.status === 202) {
        toast.success("Buy successfully");
        setPending(false);
        handleCancel();
        dispatch(IsLoadingRedux(true));
        setSignatureData({});
        setValueSelect(0);
      }
    } catch (error: any) {
      setPending(false);
      toast.error("error");
      setSignatureData({});
    }
  };

  return (
    <ModalBuyContainer>
      <ModalTabsWrapper>
        <h2>Payment Source</h2>
        <ModalTabs>
          {tabData.map((item, index) => {
            return (
              <li
                className={tabSelect === item.id ? "active" : ""}
                onClick={() => {
                  setTabSelect(item.id);
                }}
                key={index}
              >
                <figure>
                  <img
                    width={28}
                    height={27}
                    src={item.icon}
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                <p>{item.name}</p>
              </li>
            );
          })}
        </ModalTabs>
      </ModalTabsWrapper>
      <ModalBalance>
        <figure>
          <img src="/img/common/balance_icon.svg" alt="icon" loading="lazy" />
        </figure>
        {tabSelect === 1 ? (
          <>
            Balance: {convertFixed(tokenBalance)} WIC ={" "}
            {convertFixed(Number(tokenBalance) * Number(wicPrice))} USDT
          </>
        ) : (
          <>
            Balance:{" "}
            {userProfile?.wic_coin ? convertFixed(userProfile?.wic_coin) : 0}{" "}
            WIC ={" "}
            {userProfile?.wic_coin
              ? convertFixed(Number(userProfile?.wic_coin) * Number(tokenPrice))
              : 0}{" "}
            USDT
          </>
        )}
      </ModalBalance>
      <ModalSelectedData>
        {selectedData.map((item, index) => {
          const currentPackage = selectedData.find(
            (pkg) => pkg.package === userProfile?.package
          );
          const canSelectItem =
            currentPackage &&
            (item.id > currentPackage.id ||
              (item?.package === userProfile?.package &&
                item.id > currentPackage.id))
              ? false
              : true;
          return (
            <li
              style={{
                pointerEvents: canSelectItem ? "auto" : "none",
                opacity: canSelectItem ? 1 : 0.5,
              }}
              className={item.value === valueSelect ? "active" : ""}
              onClick={() => {
                setValueSelect(item.value);
              }}
              key={index}
            >
              <figure>
                <img
                  width={94}
                  height={52}
                  src={item.icon}
                  alt="icon"
                  loading="lazy"
                />
              </figure>
              <div>
                <h2>{item.title}</h2>
                <p>{item.price}</p>
                <span>
                  ≈{" "}
                  {publicKey ? (
                    <>
                      {tabSelect === 1
                        ? convertFixed(
                            (Number(item.value) / Number(wicPrice)) * 1.01
                          )
                        : convertFixed(
                            Number(item.value) / Number(tokenPrice)
                          )}{" "}
                    </>
                  ) : (
                    0
                  )}{" "}
                  {tabSelect === 1 ? (
                    <img
                      width={20}
                      height={20}
                      src="/img/common/wicdog_icon.png"
                      alt="icon"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      width={20}
                      height={20}
                      src="/img/common/wiccoin_icon.png"
                      alt="icon"
                      loading="lazy"
                    />
                  )}
                </span>
              </div>
            </li>
          );
        })}
      </ModalSelectedData>
      <ButtonCommon
        onClick={handleSignWhenBuy}
        disabled={isPending || !walletSol.publicKey || !userProfile}
        style={{
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        className="opacity"
      >
        {isPending ? (
          <Typewriter
            options={{
              strings: [
                "Transaction is still progressing...",
                "Please do not close window",
              ],
              autoStart: true,
              loop: true,
            }}
          />
        ) : (
          <p>{!publicKey ? "Please connect wallet" : "Confirm"}</p>
        )}
      </ButtonCommon>
    </ModalBuyContainer>
  );
};

export default ModalBuy;
