import { useContext } from "react";
import {
  BlockText,
  BlockTitle,
  BlockTitleFlex,
  ButtonCommon,
  TitleDecor,
} from "../../../Layout/styled";
import BlockCard from "../BlockCard";
import { VisualContainer, VisualHeader, VisualList } from "./styled";
import { ContextProviderWrapper } from "../../Context";
import { BuyHeader } from "../../../page/Buy/styled";
import { useSelector } from "react-redux";
import { useWallet } from "@solana/wallet-adapter-react";

const VisualFlex = ({
  data,
  icon,
  widthIcon,
  heightIcon,
  titleDecorMobile,
  textButtonMobile,
  functionMobile,
}: any) => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const { userProfile } = useSelector((state: any) => state.user);
  const walletSol: any = useWallet();

  return (
    <VisualContainer>
      <VisualHeader>
        {isMobile && (
          <BuyHeader>
            <TitleDecor>{titleDecorMobile}</TitleDecor>
            <ButtonCommon
                onClick={() => {
                  if (userProfile && walletSol?.publicKey) {
                    functionMobile()
                  }
                }}
                className="opacity"
              >
                {userProfile && walletSol?.publicKey ? "Buy Now" : "Please connect wallet"}
              </ButtonCommon>
          </BuyHeader>
        )}
        <figure
          style={{
            marginTop: titleDecorMobile === "STAKING WICCOIN" ? "-20px" : "",
          }}
        >
          <img
            width={widthIcon}
            height={heightIcon}
            src={icon}
            alt="icon"
            loading="lazy"
          />
        </figure>
      </VisualHeader>
      <VisualList>
        {data.map((item: any, index: any) => {
          return (
            <li key={index}>
              <BlockCard
                icon={item.icon}
                imgWidth={20}
                imgHeight={20}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>{item.name}</BlockTitle>
                      <BlockText>
                        {item.value}
                        {item.isLogoTitle ? (
                          <img
                            width={25}
                            height={25}
                            src={item.isLogoTitle}
                            alt="icon"
                            loading="lazy"
                          />
                        ) : (
                          <></>
                        )}
                      </BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
          );
        })}
      </VisualList>
    </VisualContainer>
  );
};

export default VisualFlex;
