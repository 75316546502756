import { ChartMain, ChartWrapper, TooltipChart } from "../styled";

const ChartLine = ({ tooltipRef, chartContainerRef }: any) => {
  return (
    <ChartWrapper>
      <TooltipChart ref={tooltipRef} />
      <ChartMain ref={chartContainerRef} />
    </ChartWrapper>
  );
};

export default ChartLine;
