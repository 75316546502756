import { useContext, useEffect, useState } from "react";
import BlockCard from "../../components/Common/BlockCard";
import {
  BlockText,
  BlockTitle,
  BlockTitleFlex,
  ButtonCommon,
  FrameBlock,
  TitleDecor,
} from "../../Layout/styled";
import { CardAction } from "../Home/styled";
import {
  AffiliateContainer,
  AffiliateHeader,
  AffiliateHeaderMobile,
  AffiliateInfo,
  AffiliateNetwork,
  AffiliateTableHeader,
  CardQR,
  QRInfo,
  SwitchLR,
} from "./styled";
import BinaryTree from "./Tree";
import InvitersTable from "./Inviters";
import BinaryList from "./BinaryList";
import { ContextProviderWrapper } from "../../components/Context";
import SelectCommon from "../../components/Common/Select";
import { WicAPI } from "../../services/api";
import { useWallet } from "@solana/wallet-adapter-react";
import { useSelector } from "react-redux";
import { shortenAddress } from "../../utils/addressUser";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";
import { convertFixed } from "../../utils/convertNumber";

const Affiliate = () => {
  const [switchId, setSwitchId] = useState(1);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [selectId, setSelectId] = useState(1);
  const walletSol = useWallet();
  const { userProfile } = useSelector((state: any) => state.user);
  const [affiliateData, setAffiliateData] = useState<any>([]);
  const [binaryData, setBinaryData] = useState<any>({});
  const [networkData, setNetworkData] = useState<any>({});

  const handleGetAffiliate = async () => {
    try {
      const res: any = await WicAPI.affiliate();
      if (res) {
        setAffiliateData([...res?.data.left, ...res?.data.right]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetNetworkTree = async () => {
    try {
      const res = await WicAPI.networkTree();
      if (res) {
        setBinaryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetNetwork = async () => {
    try {
      const res = await WicAPI.network();
      if (res) {
        setNetworkData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userProfile && Object.keys(userProfile).length > 1) {
      handleGetNetworkTree();
      handleGetAffiliate();
      handleGetNetwork();
    }
  }, [userProfile]);

  const handleChangeTabs = () => {
    switch (selectId) {
      case 1:
        return <InvitersTable data={affiliateData} />;
      case 2:
        return <BinaryTree binaryData={binaryData} />;
      case 3:
        return <BinaryList networkData={networkData} />;
      default:
        break;
    }
  };

  const filterStatus = [
    {
      title: "DIRECT INVITER",
      img: "",
    },
    {
      title: "BINARY GRAPH",
      img: "",
    },
    {
      title: "BINARY LIST",
      img: "",
    },
  ];

  const handleChangeValue = (e: any) => {
    setSelectId(e === "DIRECT INVITER" ? 1 : e === "BINARY GRAPH" ? 2 : 3);
  };

  return (
    <AffiliateContainer>
      <AffiliateHeader>
        {isMobile ? (
          <AffiliateHeaderMobile>
            <figure>
              <img
                width={172}
                height={136}
                src="/img/common/visual_2.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <TitleDecor>Affiliate</TitleDecor>
          </AffiliateHeaderMobile>
        ) : (
          <TitleDecor>Affiliate</TitleDecor>
        )}
      </AffiliateHeader>
      <FrameBlock>
        <AffiliateNetwork>
          <AffiliateInfo>
            <li>
              <BlockCard
                icon={"/img/common/direct_user_icon.svg"}
                imgWidth={20}
                imgHeight={20}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Direct Inviters</BlockTitle>
                      <BlockText>
                        {networkData?.total_member_direct
                          ? networkData?.total_member_direct
                          : 0}
                      </BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            <li>
              <BlockCard
                icon={"/img/common/lot_icon.svg"}
                imgWidth={20}
                imgHeight={20}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Total LOT</BlockTitle>
                      <BlockText>
                        {networkData?.total_lot
                          ? convertFixed(networkData?.total_lot)
                          : 0}
                      </BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            <li>
              <BlockCard
                icon={"/img/common/percentage_icon.svg"}
                imgWidth={20}
                imgHeight={20}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Total Brand Commission</BlockTitle>
                      <BlockText>
                        {networkData?.total_commission_branch
                          ? networkData?.total_commission_branch
                          : 0}
                      </BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            <li>
              <BlockCard
                icon={"/img/common/buypackage_icon.svg"}
                imgWidth={20}
                imgHeight={20}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Group Package Order</BlockTitle>
                      <BlockText>
                        {networkData
                          ? convertFixed(
                              networkData?.left_team_revenue?.usd +
                                networkData?.right_team_revenue?.usd || 0
                            )
                          : 0}
                      </BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            {/* <li>
              <BlockCard
                icon={"/img/common/left_user_icon.svg"}
                imgWidth={15}
                imgHeight={15}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Left - Available</BlockTitle>
                      <BlockText>0</BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li>
            <li>
              <BlockCard
                icon={"/img/common/right_user_icon.svg"}
                imgWidth={15}
                imgHeight={15}
                component={
                  <BlockTitleFlex>
                    <div className="column">
                      <BlockTitle>Right - Available</BlockTitle>
                      <BlockText>0</BlockText>
                    </div>
                  </BlockTitleFlex>
                }
              />
            </li> */}
          </AffiliateInfo>
          <CardAction>
            <figure>
              <img
                width={82}
                height={69}
                src="/img/common/logo_big.png"
                alt="icon"
                loading="lazy"
              />
            </figure>
            <CardQR>
              <figure className={!userProfile ? "empty" : ""}>
                {userProfile ? (
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={
                      switchId === 1
                        ? `https://app.wicchain.net/ref/${shortenAddress(
                            userProfile?.left_code
                          )}`
                        : `https://app.wicchain.net/ref/${shortenAddress(
                            userProfile?.right_code
                          )}`
                    }
                  />
                ) : (
                  <></>
                )}
              </figure>
              <QRInfo>
                <p>Using the affiliate QR code for faster invitation.</p>
                <SwitchLR>
                  <li
                    className={switchId === 1 ? "active" : ""}
                    onClick={() => {
                      setSwitchId(1);
                    }}
                  >
                    <p>Left Link</p>
                  </li>
                  <li
                    className={switchId === 2 ? "active" : ""}
                    onClick={() => {
                      setSwitchId(2);
                    }}
                  >
                    <p>Right Link</p>
                  </li>
                </SwitchLR>
                <div style={{ marginBottom: "10px" }}>
                  <p>
                    {walletSol.publicKey ? (
                      <>
                        {switchId === 1
                          ? `https://app.wicchain.net/ref/${shortenAddress(
                              userProfile?.left_code
                                ? userProfile?.left_code
                                : "--"
                            )}`
                          : `https://app.wicchain.net/ref/${shortenAddress(
                              userProfile?.right_code
                                ? userProfile?.right_code
                                : "--"
                            )}`}
                      </>
                    ) : (
                      <>Connect Wallet</>
                    )}
                  </p>
                  {walletSol.publicKey && (
                    <CopyToClipboard
                      text={
                        switchId === 1
                          ? `https://app.wicchain.net/ref/${
                              userProfile?.left_code
                                ? userProfile?.left_code
                                : "--"
                            }`
                          : `https://app.wicchain.net/ref/${
                              userProfile?.right_code
                                ? userProfile?.right_code
                                : "--"
                            }`
                      }
                      onCopy={() => toast.success("Coppied !")}
                    >
                      <figure
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img
                          width={42}
                          height={41}
                          src="/img/common/copy.jpg"
                          alt="icon"
                          loading="lazy"
                        />
                      </figure>
                    </CopyToClipboard>
                  )}
                </div>
                <div>
                  {walletSol.publicKey ? (
                    <p>
                      <span
                        style={{
                          color: "#fc0",
                        }}
                      >
                        Sponser:{" "}
                      </span>
                      {userProfile?.sponser
                        ? shortenAddress(userProfile?.sponser)
                        : "--"}
                    </p>
                  ) : (
                    <></>
                  )}
                  {walletSol.publicKey && (
                    <CopyToClipboard
                      text={userProfile?.sponser}
                      onCopy={() => toast.success("Coppied !")}
                    >
                      <figure
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img
                          width={42}
                          height={41}
                          src="/img/common/copy.jpg"
                          alt="icon"
                          loading="lazy"
                        />
                      </figure>
                    </CopyToClipboard>
                  )}
                </div>
              </QRInfo>
            </CardQR>
          </CardAction>
        </AffiliateNetwork>
      </FrameBlock>
      <FrameBlock>
        <AffiliateTableHeader>
          <h2>Affiliate List</h2>
          {walletSol?.publicKey && Object.keys(binaryData).length > 1 ? (
            <>
              {isMobile ? (
                <SelectCommon
                  data={filterStatus}
                  onChange={handleChangeValue}
                />
              ) : (
                <div>
                  <ButtonCommon
                    onClick={() => {
                      setSelectId(1);
                    }}
                    className={selectId === 1 ? "opacity active" : "opacity"}
                  >
                    Direct Inviter
                  </ButtonCommon>
                  <ButtonCommon
                    onClick={() => {
                      setSelectId(2);
                    }}
                    className={selectId === 2 ? "opacity active" : "opacity"}
                  >
                    Binary Graph
                  </ButtonCommon>
                  <ButtonCommon
                    onClick={() => {
                      setSelectId(3);
                    }}
                    className={selectId === 3 ? "opacity active" : "opacity"}
                  >
                    Binary List
                  </ButtonCommon>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </AffiliateTableHeader>
        {handleChangeTabs()}
      </FrameBlock>
    </AffiliateContainer>
  );
};

export default Affiliate;
