import styled from "styled-components";
import { BlockCardLayout, TitleDecor } from "../../Layout/styled";
import { CardAction } from "../Home/styled";
import { SelectContainer } from "../../components/Common/Select/styled";

export const AffiliateContainer = styled.div`
  position: relative;
  z-index: 5;
`;
export const AffiliateHeader = styled.div`
  margin-bottom: 15px;
  ${TitleDecor} {
    margin-bottom: 0;
    text-align: left;
  }
`;
export const AffiliateInfo = styled.ul`
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  & > li {
    width: calc(50% - 5px);
    ${BlockCardLayout} {
      width: auto;
    }
  }
  @media screen and (max-width: 767px) {
    & > li {
      width: calc(50% - 3px);
    }
  }
`;
export const AffiliateNetwork = styled.div`
  display: flex;
  gap: 5px;
  ${CardAction} {
    width: auto;
    justify-content: center;
    & > figure {
      position: absolute;
      top: -20px;
      right: -23px;
      width: 102px;
      img {
        width: 100%;
        height: auto;
      }
      &::before {
        top: -280;
        right: -90px;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        background: url("/img/Affiliate/icon_effect.png") no-repeat center /
          100% auto;
        width: 313px;
        height: 221px;
        pointer-events: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    ${CardAction} {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
`;
export const CardQR = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  gap: 40px;
  padding-left: 30px;
  align-items: center;
  & > figure {
    position: relative;
    width: 104px;
    height: 104px;
    flex-shrink: 0;
    border-radius: 10px;
    &.empty {
      background-color: #fff;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -80px;
      margin-left: -82px;
      width: 161px;
      height: 161px;
      background: url("/img/Affiliate/qr_frame.png") no-repeat center / 100%
        auto;
      pointer-events: none;
    }
  }
  @media screen and (max-width: 767px) {
    padding-left: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & > figure {
      width: 84px;
      height: 84px;
      &::before {
        width: 120px;
        height: 120px;
        margin-top: -60px;
        margin-left: -61px;
      }
    }
  }
`;
export const QRInfo = styled.div`
  & > p {
    color: #757575;
    font-size: 12px;
    margin-bottom: 10px;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 7px;
    & > p {
      padding: 12px 8px;
      background: #2c2c2c;
      font-size: 14px;
      color: #fff;
      border-radius: 7px;
    }
    & > figure {
      width: 42px;
      height: 40px;
      border-radius: 7px;
      overflow: hidden;
      transition: all 0.15s linear;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    & > div {
      & > figure {
        flex-shrink: 0;
      }
      & > p {
        white-space: nowrap;
        overflow: hidden;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
`;
export const AffiliateTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  h2 {
    margin-bottom: 0;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  @media screen and (max-width: 767px) {
    ${SelectContainer} {
      width: 150px;
    }
  }
`;
export const AffiliateHeaderMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: url("/img/Affiliate/affiliate_header_bg.jpg") no-repeat center /
    cover;
  figure {
    margin-top: -20px;
  }
  ${TitleDecor} {
    font-size: 24px;
  }
`;
export const SwitchLR = styled.ul`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    border-radius: 4px;
    border: 1px solid rgba(63, 62, 62, 0.4);
    background: #1d1d1d;
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.15s linear;
    cursor: pointer;
    &:hover,
    &.active {
      color: #000;
      background-color: #fff;
      border-color: #fff;
    }
  }
`;
