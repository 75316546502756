import { useContext, useState } from "react";
import { BinaryListContainer } from "./styled";
import { ContextProviderWrapper } from "../../../components/Context";
import LTeam from "./LeftTeam";
import RTeam from "./RightTeam";
import SwitchTab from "../../../components/Common/SwitchTabs";

const BinaryList = ({ networkData }: any) => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [tabClicked, setTabClicked] = useState(1);

  const handleSwitchTabMobile = () => {
    switch (tabClicked) {
      case 1:
        return <LTeam data={networkData} />;
      case 2:
        return <RTeam data={networkData} />;
      default:
        break;
    }
  };

  const dataTabs = [
    {
      id: 1,
      tabName: "Left Team",
    },
    {
      id: 2,
      tabName: "Right Team",
    },
  ];

  return (
    <BinaryListContainer>
      {isMobile ? (
        <>
          <SwitchTab
            dataTabs={dataTabs}
            switchTabFunction={setTabClicked}
            tabClicked={tabClicked}
          />
          {handleSwitchTabMobile()}
        </>
      ) : (
        <>
          <LTeam data={networkData} />
          <RTeam data={networkData} />
        </>
      )}
    </BinaryListContainer>
  );
};

export default BinaryList;
