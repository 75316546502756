import React from "react";
import { EmptyTextContainer } from "./styled";

const EmptyText = ({ text }: any) => {
  return (
    <EmptyTextContainer>
      <p>{text}</p>
    </EmptyTextContainer>
  );
};

export default EmptyText;
