import BlockCard from "../../components/Common/BlockCard";
import {
  BlockText,
  BlockTitle,
  BlockTitleFlex,
  ButtonCommon,
  FrameBlock,
  FrameBlockSmall,
} from "../../Layout/styled";
import DashboardNetwork from "./Network";
import {
  CardColumn,
  CardDescription,
  CardInfo,
  DashboardContainer,
  FrameBlockHeader,
} from "./styled";
import DashboardHistory from "./Transaction";
import { useContext, useState } from "react";
import { ContextProviderWrapper } from "../../components/Context";
import ModalWrapper from "../../components/Common/Modal";
import FilterTransaction from "./Transaction/Filter";
import ModalTransfer from "./Modal/Transfer";
import ModalSwap from "./Modal/Swap";
import { useSelector } from "react-redux";
import { convertFixed } from "../../utils/convertNumber";
import CardLevel from "../../components/Common/CardLevel";
import CardActions from "./CardAction";

const Home = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const { userProfile, wicPrice, tokenPrice } = useSelector(
    (state: any) => state.user
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalTransferOpen, setIsModalTransferOpen] = useState(false);

  const handleOkTransfer = () => {
    setIsModalTransferOpen(false);
  };

  const handleCancelTransfer = () => {
    setIsModalTransferOpen(false);
  };

  const [isModalSwapOpen, setIsModalSwapOpen] = useState(false);

  const handleOkSwap = () => {
    setIsModalSwapOpen(false);
  };

  const handleCancelSwap = () => {
    setIsModalSwapOpen(false);
  };

  return (
    <>
      <ModalWrapper
        component={
          <ModalTransfer handleCancelTransfer={handleCancelTransfer} />
        }
        title="TRANSFER"
        icon={"/img/common/modal_staking_icon.png"}
        iconWidth={82}
        iconHeight={69}
        isModalOpen={isModalTransferOpen}
        handleOk={handleOkTransfer}
        handleCancel={handleCancelTransfer}
      />
      <ModalWrapper
        component={<ModalSwap handleCancelSwap={handleCancelSwap} />}
        title="SWAP"
        icon={"/img/common/modal_staking_icon.png"}
        iconWidth={82}
        iconHeight={69}
        isModalOpen={isModalSwapOpen}
        handleOk={handleOkSwap}
        handleCancel={handleCancelSwap}
      />
      <DashboardContainer>
        <FrameBlock>
          <CardInfo>
            <CardDescription>
              <CardLevel />
              <CardColumn>
                <BlockCard
                  icon={"/img/common/wiccoin_icon.png"}
                  imgWidth={30}
                  imgHeight={29}
                  component={
                    <BlockTitleFlex>
                      <div className="column">
                        <BlockTitle>WIC Balance</BlockTitle>
                        <BlockText>
                          {userProfile?.wic_coin
                            ? convertFixed(userProfile?.wic_coin)
                            : "0.00"}
                          <span>WIC</span>
                        </BlockText>
                      </div>
                    </BlockTitleFlex>
                  }
                />
                <BlockCard
                  icon={"/img/common/wiccoin_icon.png"}
                  imgWidth={30}
                  imgHeight={29}
                  component={
                    <BlockTitleFlex>
                      <div className="column">
                        <BlockTitle>Total Commission</BlockTitle>
                        <BlockText>
                          {userProfile?.total_commission
                            ? convertFixed(userProfile?.total_commission)
                            : "0"}
                        </BlockText>
                      </div>
                    </BlockTitleFlex>
                  }
                />
              </CardColumn>
              <CardColumn>
                <BlockCard
                  icon={"/img/common/wiccoin_icon.png"}
                  imgWidth={30}
                  imgHeight={29}
                  component={
                    <BlockTitleFlex>
                      <div className="column">
                        <BlockTitle>WIC Coin Rate</BlockTitle>
                        <BlockText>
                          ${convertFixed(Number(tokenPrice))}
                        </BlockText>
                      </div>
                    </BlockTitleFlex>
                  }
                />
                <BlockCard
                  icon={"/img/common/wicdog_icon.png"}
                  imgWidth={30}
                  imgHeight={29}
                  component={
                    <BlockTitleFlex>
                      <div className="column">
                        <BlockTitle>WIC (DogWithCap) Rate</BlockTitle>
                        <BlockText>${convertFixed(Number(wicPrice))}</BlockText>
                      </div>
                    </BlockTitleFlex>
                  }
                />
              </CardColumn>
              <CardColumn>
                <BlockCard
                  icon={"/img/common/wiccoin_icon.png"}
                  imgWidth={30}
                  imgHeight={29}
                  component={
                    <BlockTitleFlex>
                      <div className="column">
                        <BlockTitle>Profit Daily</BlockTitle>
                        <BlockText>
                          {userProfile?.total_profit_daily
                            ? convertFixed(userProfile?.total_profit_daily)
                            : "0"}
                        </BlockText>
                      </div>
                    </BlockTitleFlex>
                  }
                />
                <BlockCard
                  icon={"/img/common/wiccoin_icon.png"}
                  imgWidth={30}
                  imgHeight={29}
                  component={
                    <BlockTitleFlex>
                      <div className="column">
                        <BlockTitle>Network Comission</BlockTitle>
                        <BlockText>
                          {userProfile?.total_network_commission
                            ? convertFixed(
                                userProfile?.total_network_commission
                              )
                            : "0"}
                        </BlockText>
                      </div>
                    </BlockTitleFlex>
                  }
                />
              </CardColumn>
            </CardDescription>
            <CardActions
              setIsModalTransferOpen={setIsModalTransferOpen}
              setIsModalSwapOpen={setIsModalSwapOpen}
            />
          </CardInfo>
        </FrameBlock>
        {/* <FrameBlockSmall>
          <BlockCard
            icon={"/img/common/ranking_icon.svg"}
            imgWidth={20}
            imgHeight={19}
            component={
              <BlockTitleFlex>
                <div className="column">
                  <BlockTitle>Ranking</BlockTitle>
                  <BlockText>Sun</BlockText>
                </div>
              </BlockTitleFlex>
            }
          />
          <BlockCard
            icon={"/img/common/ranking_icon.svg"}
            imgWidth={20}
            imgHeight={19}
            component={
              <BlockTitleFlex>
                <div className="column">
                  <BlockTitle>Max-Out</BlockTitle>
                  <BlockText>0% / 300%</BlockText>
                </div>
              </BlockTitleFlex>
            }
          />
        </FrameBlockSmall> */}
        <FrameBlock>
          <FrameBlockHeader>
            <h2>Transaction History</h2>
            {isMobile && (
              <ButtonCommon
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className="opacity"
              >
                FILTER
              </ButtonCommon>
            )}
          </FrameBlockHeader>
          <DashboardHistory />
        </FrameBlock>
        <DashboardNetwork />
      </DashboardContainer>
      {isMobile && (
        <ModalWrapper
          component={<FilterTransaction />}
          title="Filter"
          icon={"/img/common/filter_icon.svg"}
          iconWidth={40}
          iconHeight={40}
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default Home;
