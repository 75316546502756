import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useEffect } from "react";
import { ButtonCommon } from "../../../Layout/styled";
import { useWalletMultiButton } from "@solana/wallet-adapter-base-ui";

const Wallet = () => {
  const { setVisible: setModalVisible } = useWalletModal();
  const { buttonState, onConnect } = useWalletMultiButton({
    onSelectWallet() {
      setModalVisible(true);
    },
  });

  const handleStateConnectSol = () => {
    switch (buttonState) {
      case "connecting":
        return "Connecting...";
      default:
        return "Connect Wallet";
    }
  };

  // useEffect(() => {
  //   if (buttonState === "has-wallet") {
  //     if (onConnect) {
  //       onConnect();
  //     }
  //   }
  // }, [buttonState]);

  return (
    <ButtonCommon className="opacity">{handleStateConnectSol()}</ButtonCommon>
  );
};

export default Wallet;
